import { AfterViewInit, Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

import { Company } from '../../../limo-company/limo-company';
import { LimoCompanyService } from '../../../limo-company/limo-company.service';
import { DynamicModalService } from '../dynamic-modal/dynamic-modal.service';

@Component({
  selector: 'delete-limo-company',
  templateUrl: 'delete-limo-company.template.html',
  providers: [LimoCompanyService]
})

export class DeleteLimoCompanyModalComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('modalWindow') limoDeleteModal;
  new_limo_delete: FormGroup;
  isFormSubmitted = false;
  company: Company;
  onClose: Subject<any>;

  select2Options: Select2Options = {
    multiple: true,
    theme: 'bootstrap4'
  };

  constructor(
    private formBuilder: FormBuilder,
    private limoCompanyService: LimoCompanyService,
    private dynamicModalService: DynamicModalService,
    private injector: Injector
  ) {}

  ngOnInit() {
    this.company = this.injector.get('company');
    this.new_limo_delete = this.formBuilder.group({
      id: ['', '']
    });
    this.onClose = this.injector.get('onClose');
  }

  ngAfterViewInit() {
    this.limoDeleteModal.show();
    this.limoDeleteModal.onHidden.subscribe(() => this.dynamicModalService.modalOnClose.next(null));
  }

  onSubmit(form) {
    this.delete_();
  }


  delete_() {
    this.limoCompanyService.deleteCompany(this.company.id)
      .subscribe(data => {
        this.limoDeleteModal.hide();
        this.new_limo_delete.reset();
        this.closeModal(data);
      });
  }

  closeModal(data: any) {
    this.onClose.next(data);
    this.onClose.complete();
  }

  ngOnDestroy() {}
}
