import { HttpEvent, HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_V2, V2_HEADER } from 'src/app/overview-v2/document-transcription-v2/shared/constants';

@Injectable()
export class BaseUriInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.startsWith('http')) {
      const baseApiUrl = request.headers.get(V2_HEADER) ? `/${API_V2}` : '/api/';
      request = request.clone({ url: `${baseApiUrl}${request.url}` });
    }
    return next.handle(request);
  }
}
