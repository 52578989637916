export const permissionsConstant = [
  {
    path: 'dashboard',
    right: 'admin-dashboard'
  },
  {
    path: 'overview',
    right: 'admin-overview'
  },
  {
    path: 'Manage Limo Companies',
    right: 'admin-view-limo-companies'
  },
  {
    path: 'training-overview',
    right: 'admin-view-training-slots'
  },
  {
    path: 'incomplete-profiles',
    right: 'admin-view-incomplete-registrations'
  },
  {
    path: 'trainer-management',
    right: 'admin-view-trainers'
  },
  {
    path: 'car-check',
    right: 'admin-car-check-and-training'
  },
  {
    path: 'training-slots',
    right: 'admin-view-training-slots'
  },
  {
    path: 'transcription-listing',
    right: 'admin-update-transcriptions'
  },
  {
    path: 'approve-documents',
    right: 'admin-view-lead-profile'
  },
  {
    path: 'reporting',
    right: 'admin-reporting'
  },
  {
    path: 'change-user-password',
    right: 'change-user-password'
  },
  {
    path: 'admin_update_training',
    right: 'admin_update_training'
  },
  {
    path: 'download-history',
    right: 'admin-download-list-for-solution'
  },
  {
    path: 'download-all-profile',
    right: 'download-all-profile'
  },
  {
    path: 'download-data',
    right: 'download-data'
  },
  {
    path: 'find-a-captain',
    right: 'find-a-captain'
  },
  {
    path: 'user-management',
    right: 'admin-manage-users'
  },
  {
    path: 'replicate-city',
    right: 'replicate-city'
  },
  {
    path: 'add-captain',
    right: 'admin-add-captain'
  },
  {
    path: 'add-supplier',
    right: 'admin-add-captain'
  },
  {
    path: 'admin-view-city-document',
    right: 'admin-view-city-document'
  },
  {
    path: 'admin-edit-city-document',
    right: 'admin-edit-city-document'
  },
  {
    path: 'admin-view-form',
    right: 'admin-view-form'
  },
  {
    path: 'admin-edit-form',
    right: 'admin-edit-form'
  }
];

export const referralPermissions = {
  show: 'show-referral-id',
  enable: 'enable-referral-id'
};

export enum USER_PERMISSIONS {
  hide_transcription_action_display = 'hide-transcription-action-display',
  hide_alert_banner_display = 'hide-alert-banner-display',
  hide_transcription_rotate_buttons = 'hide-transcription-rotate-buttons'
};

