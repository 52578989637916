import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class CarCheckService {
  formats: Array<string> = ['image/jpeg', 'image/png', 'application/pdf'];
  constructor(
    private readonly httpClient: HttpClient
  ) {}

  getCars(page = 1, search = '', statusId?: string, sortBy?: string,
    sortOrder?: string): Observable<any> {
    const args: any = { };
    args.page = String(page);
    if (search) {
      args.search = search;
    }
    if (statusId !== '0') {
      args.status_id = statusId;
    }
    if (sortBy) {
      args.sort_by = sortBy;
    }
    if (sortOrder) {
      args.sort_order = sortOrder;
    }
    return this.httpClient.get('car/list', { params: new HttpParams({ fromObject: args }) });
  }

  getCarsById(id: number) {
    return this.httpClient.get(`car/list/${id}`);
  }

  checkCar(status: any) {
    return this.httpClient.put(`carcheck/${status.id}`, status);
  }

  isImageUrl(url: string) {
    return(url.match(/\.(jpeg|jpg|gif|png)$/) != null);
  }

  getCar(id: number) {
    return this.httpClient.get(`car/${id}`);
  }

  isValidFile(fileType: string) {
    return (this.formats.indexOf(fileType) > -1);
  }
}
