import { AfterViewInit, Component, Injector, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { ToastrMessages } from 'src/app/common/constants';

import { CarCheckService } from '../../../car-check/car-check.service';
import { Car } from '../../../car-check/data';
import { DateService } from '../../date/date.service';
import { SpinnerService } from '../../spinner/spinner.service';
import { ToastrService } from '../../toastr/toastr.service';

@Component({
  selector: 'supplier-details',
  templateUrl: './supplier-details.template.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./supplier-details.styles.scss']
})

export class SupplierDetailsModalComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('modalWindow') modalWindow: ModalDirective;
  onClose: Subject<void>;
  uploadingFile = false;
  supplierForm: FormGroup;
  previewImage = false;
  car: Car;
  file: { data?: string, name?: string, preview?: string, current?: string } = {};
  preview: any = {
    data: ''
  };
  statuses: any = [
    { value: '2', display: 'Approve Car Check', checked: false },
    { value: '3', display: 'Reject Car Check', checked: false }
  ];
  filter: any = {};

  constructor(
    private formBuilder: FormBuilder,
    private carCheckService: CarCheckService,
    private toastrService: ToastrService,
    private spinnerService: SpinnerService,
    private dateService: DateService,
    private injector: Injector
  ) { }

  ngOnInit() {
    this.car = this.injector.get('car');
    this.onClose = this.injector.get('onClose');
    this.filter.startDate = this.dateService.getStartOfDay(new Date);
    this.filter.startDate = this.dateService.getStartOfDay(new Date);

    this.supplierForm = this.formBuilder.group({
      name: [`${this.car.supplier.first_name} ${this.car.supplier.last_name}`],
      mobile_number: [this.car.supplier.cell_phone],
      registration: [this.car.registration],
      make: [''],
      model: [this.car.model.name],
      color: [''],
      year: [this.car.year.year],
      status: [String(this.car.status.id)]
    });

    this.carCheckService.getCar(this.car.id).pipe(
      first()).subscribe((response: any) => this.file.data = response['certificate_document']);

    this.supplierForm.patchValue({
      make: this.car.model.make ? this.car.model.make.title : '',
      color: this.car.color ? this.car.color.title : '',
      status: this.car.status.id ? String(this.car.status.id) : ''
    });
  }

  ngAfterViewInit() {
    this.modalWindow.show();
  }

  uploadFile(file) {
    this.file = file;
  }

  changeStatus(status: string) {
    this.supplierForm.patchValue({ status: status });
  }

  removeImage() {
    this.file.name = '';
    this.file.data = '';
    this.file.preview = '';
  }

  checkCar() {
    this.uploadingFile = true;
    this.spinnerService.change(true);

    const car: Car = {
      status_id: this.supplierForm.get('status').value,
      car_check_form: this.file.data,
      id: this.car.id
    };

    this.carCheckService.checkCar(car).pipe(
      tap(() => this.spinnerService.change(false)))
      .subscribe((res) => {
        this.closeModal();
        this.toastrService.openToastr(ToastrMessages.carStatusChanged, ToastrMessages.success);
      }, (errors) => {
        this.toastrService.openToastr(errors.errors[0].title, ToastrMessages.error);
        this.uploadingFile = false;
      });
  }

  closeModal() {
    this.onClose.next();
    this.modalWindow.hide();
  }

  showImage() {
    if (this.preview.data !== 'assets/img/pdf-icon.png') {
      this.previewImage = true;
    }
  }

  upload(file) {
    this.file = file;
  }

  ngOnDestroy() { }
}
