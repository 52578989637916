/*
 * Angular 2 decorators and services
 */
import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { SpinnerService } from './common';
import { DynamicModalService, IComponentModel } from './common/modals/dynamic-modal/dynamic-modal.service';
import { filter } from 'rxjs/operators';
import { AuthHelper } from './common/auth/auth.helper';


declare let ga: Function;

/*
 * App Component
 * Top Level Component
 */
@Component({
  selector: 'app',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['../styles/application.scss'],
  template: `
      <spinner *ngIf="showSpinner"></spinner>
      <router-outlet></router-outlet>
      <dynamic-modal [componentData]="componentData"></dynamic-modal>`
})
export class App implements OnInit {
  showSpinner: boolean;
  componentData: IComponentModel;

  constructor(
    private readonly spinnerService: SpinnerService,
    private readonly dynamicModalService: DynamicModalService,
    private readonly router: Router,
    private readonly authHelper: AuthHelper
  ) { }

  ngOnInit() {
    this.authHelper.initLogin();

    this.dynamicModalService.componentData
      .subscribe((componentData: IComponentModel) => this.componentData = componentData);

    this.spinnerService.spinner
      .subscribe((status) => this.showSpinner = status);

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(this.sendPageView);
  }

  private sendPageView(event: NavigationEnd): void {
    let page = event.urlAfterRedirects;

    if (event.urlAfterRedirects.startsWith('/overview')) {
      page = event.urlAfterRedirects
        .replace(/keyword=(\w+)/, 'keyword={keyword}');
    }

    if (event.urlAfterRedirects.startsWith('/approve-documents')) {
      const moduleType = event.urlAfterRedirects
        .replace(/\?.*/g, '')
        .split('/')
        .filter(token => !!token)
        .pop();

      page = `/approve-documents/${moduleType}`;
    }

    ga('set', 'page', page);
    ga('send', 'pageview');
  }

}
