import { COLOR, STATUS_MAP } from '../constants';

export type STATUS = 'cumulative' | 'transcription' | 'training' | 'carCheck' | 'download' | 'externalVerification';
export type ACTION = 'viewProfile' | 'checkIn' | 'bookTrainingSlot' | 'forceTrainingResult' | 'sendTrainingReminder' 
| 'carCheck' | 'viewExternalVerificationDetails' | 'retryExternalVerification';

export interface Status {
  id: number;
  title: string;
  icon?: string;
  color?: COLOR;
}

export interface StatusGroup {
  title: string;
  possibleValues: Status[];
}

export type CaptainLeadStatusGroupResponse = { [key in STATUS]: StatusGroup; };

export type VehicleLeadStatusGroupResponse = { [key in STATUS]: StatusGroup; };

export type SupplierLeadStatusGroupResponse = { [key in STATUS]: StatusGroup; };

export interface TranscriptionStatusDetails {
  transcriber: {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
  };
}

export interface TrainingStatusDetails {
  slot: {
    slotId: number;
    checkIn: number;
    checkedInAt: null | number;
    earnedScore: null | number;
    totalScore: number;
    startTime: number;
  };
}

export type CarCheckStatusDetails = any;

export interface DownloadStatusDetails {
  sgExportTime: number;
  captainId: number;
  comment: string;
}

export interface VehicleDownloadStatusDetails {
  sgExportTime: number;
  vehicleId: number;
  comment: string;
}

export interface SupplierDownloadStatusDetails {
  sgExportTime: number;
  coreId: number;
  comment: string;
}

export interface StatusDetails<T> {
  currentStatus: Status;
  details: T;
}

export class CaptainLead {
  id: number;
  profile?: {
    email: string;
    firstName: string;
    lastName: string;
    cellPhone: string;
    cnic: string;
    createdAt: number;
    profilePicture: string;
    captainTypeId: number;
    cityId: number;
  };
  status?: {
    cumulative: Status;
    breakdown: {
      transcription: StatusDetails<TranscriptionStatusDetails>;
      training: StatusDetails<TrainingStatusDetails>;
      carCheck: StatusDetails<CarCheckStatusDetails>;
      externalVerification: StatusDetails<any>;
      download: StatusDetails<DownloadStatusDetails>;
    };
  };
  actionsApplicable?: { [key in ACTION]: { enabled: boolean; reason: string } };
  expanded?: boolean;

  get fullName(): string {
    return CaptainLead.getFullName(this.profile.firstName, this.profile.lastName);
  }

  constructor(json: any) {
    Object.keys(json).forEach(key => this[key] = json[key]);
    if (this.status) {
      this.appendIconAndColorToStatuses();
    }
  }

  static getFullName(firstName, lastName) {
    let fullName = '';
    if (firstName) fullName += `${firstName} `;
    if (lastName) fullName += lastName;
    return fullName;
  }

  private appendIconAndColorToStatuses() {
    this.status.cumulative = {
      ...this.status.cumulative,
      color: STATUS_MAP.cumulative.colors[this.status.cumulative.id]
    };
    Object.keys(this.status.breakdown)
      .forEach(statusKey => {
        const currentStatus = this.status.breakdown[statusKey].currentStatus;
        this.status.breakdown[statusKey].currentStatus = {
          ...currentStatus,
          icon: STATUS_MAP[statusKey].icon,
          color: STATUS_MAP[statusKey].colors[currentStatus.id]
        };
      });
  }
}

export class VehicleLead {
  id: number;
  profile?: {
    makeId: number;
    modelId: number;
    plate: string;
    createdAt: number;
    cityId: number
    ownerName: string;
  };
  model?: {
    id: number;
    title: string;
    status: number;
    referenceId: number;
    carMakeId: number;
  };
  make?: {
    id: number;
    title: string;
    status: number;
    referenceId: number;
  };
  status?: {
    cumulative: Status;
    breakdown: {
      transcription: StatusDetails<TranscriptionStatusDetails>;
      carCheck: StatusDetails<CarCheckStatusDetails>;
      download: StatusDetails<VehicleDownloadStatusDetails>;
    };
  };
  actionsApplicable?: { [key in ACTION]: { enabled: boolean; reason: string } };
  expanded?: boolean;

  constructor(json: any) {
    Object.keys(json).forEach(key => this[key] = json[key]);
    if (this.status) {
      this.appendIconAndColorToStatuses();
    }
  }

  private appendIconAndColorToStatuses() {
    this.status.cumulative = {
      ...this.status.cumulative,
      color: STATUS_MAP.cumulative.colors[this.status.cumulative.id]
    };
    Object.keys(this.status.breakdown)
      .forEach(statusKey => {
        const currentStatus = this.status.breakdown[statusKey].currentStatus;
        this.status.breakdown[statusKey].currentStatus = {
          ...currentStatus,
          icon: STATUS_MAP[statusKey].icon,
          color: STATUS_MAP[statusKey].colors[currentStatus.id]
        };
      });
  }
}

export class SupplierLead {
  id: number;
  profile?: {
    name: string;
    phoneNumber: string;
    email: string;
    created: number;
    code: string;
    createdAt: number;
    referencePrimaryAdminUserId: number;
    referenceServiceProviderId: number;
    solutionSystemId: number;
    status: number;
  };
  status?: {
    cumulative: Status;
    breakdown: {
      transcription: StatusDetails<TranscriptionStatusDetails>;
      download: StatusDetails<SupplierDownloadStatusDetails>;
    };
  };
  actionsApplicable?: { [key in ACTION]: { enabled: boolean; reason: string } };
  expanded?: boolean;

  constructor(json: any) {
    Object.keys(json).forEach(key => this[key] = json[key]);
    if (this.status) {
      this.appendIconAndColorToStatuses();
    }
  }

  private appendIconAndColorToStatuses() {
    this.status.cumulative = {
      ...this.status.cumulative,
      color: STATUS_MAP.cumulative.colors[this.status.cumulative.id]
    };
    Object.keys(this.status.breakdown)
      .forEach(statusKey => {
        const currentStatus = this.status.breakdown[statusKey].currentStatus;
        this.status.breakdown[statusKey].currentStatus = {
          ...currentStatus,
          icon: STATUS_MAP[statusKey].icon,
          color: STATUS_MAP[statusKey].colors[currentStatus.id]
        };
      });
  }
}

export interface Action {
  actionKey: ACTION;
  header: {
    title: string;
    icon: string;
    color?: COLOR;
  };
  subTitle?: string;
  description?: string;
}

export type StatusActionMap = { [key in STATUS]?: Action };

export enum TRAINING_TYPE {
  ONLINE,
  PHYSICAL
}

export type TrainingTypes = keyof typeof TRAINING_TYPE;

export interface ExternalStatusDetail {
  details: string[];
  status: string;
  updatedAt: number;
  vendor: string;
}

export class Lead {
  id: number;
  name: string;
  phoneNumber: string;
}
