import { Injectable } from '@angular/core';
import * as moment from 'moment';
declare var require;
const hmoment = require('moment-hijri');

import { CitiesService } from '../cities/cities.service';
import { hijriDateConfig } from '../constants';

@Injectable()
export class DateService {
  offset: number;
  time: number;

  public formats = {
    date: 'YYYY-MM-DD',
    fullDate: 'YYYY-MM-DDTHH:mm:ss',
    fullISODate: 'DD MMM YYYY, hh:mm A',
    fullDateWithZone: 'YYYY-MM-DDTHH:mm:ssZ',
    fullDateWithoutZone: 'YYYY-MM-DDTHH:mm:ss+00:00'
  };

  constructor(private readonly citiesService: CitiesService) {
    this.citiesService.getCity()
      .subscribe((city) => this.setCity(city));
  }

  setCity(city: any) {
    this.offset = city.offset - moment().utcOffset();
    this.time = city.offset;
  }

  getDate(date: Date | string, pattern: string = this.formats.date, byCity?: boolean): string {
    return byCity ? moment(date).locale('en').add(this.offset, 'minutes').format(pattern) : moment(date).locale('en').format(pattern);
  }


  getFullDate(date: Date | string): string {
    return moment(date).locale('en').format(this.formats.fullDate);
  }

  getFullDateWithoutZone(date: Date | string): string {
    return moment(date).format(this.formats.fullDateWithoutZone);
  }

  getFullDateWithZone(date: Date | string, byCity?: boolean): string {
    if (byCity) {
      return moment(date).locale('en').add(this.offset, 'minutes').format(this.formats.fullDateWithZone);
    } else {
      return moment(date).locale('en').format(this.formats.fullDateWithZone);
    }
  }

  getCityDate(date?: Date | string): Date {
    if (!date) {
      return moment().locale('en').add(this.offset, 'minutes').toDate();
    }
    return moment(date).locale('en').add(this.offset, 'minutes').toDate();
  }

  getFullDateWithCityZone(date: Date | string): string {
    return moment(date).locale('en').seconds(0).format(this.formats.fullDate) + this.offsetToZone();
  }

  getRelativeDate(direction: string, unit: string, diff: number): Date {
    return moment()[direction](diff, unit).locale('en').toDate();
  }

  getDuration(startTime, endTime, diffUnit: string): any {
    return moment.duration(moment(startTime).locale('en').diff(moment(endTime)))[diffUnit]();
  }

  getISODate(date: Date | string): string {
    return moment(date).locale('en').toISOString();
  }

  getStartOfDay(date: Date | string, asDate?: boolean): any {
    if (asDate) {
      return moment(date).locale('en').startOf('day').toDate();
    }
    return moment(date).locale('en').startOf('day').format(this.formats.fullDateWithZone);
  }

  getEndOfDay(date: Date | string, asDate?: boolean): any {
    if (asDate) {
      return moment(date).locale('en').endOf('day').toDate();
    }
    return moment(date).locale('en').endOf('day').format(this.formats.fullDateWithZone);
  }

  getDayName(date: Date, format = 'DD MMM YYYY'): string {
    if (moment().isSame(date, 'day')) {
      return 'Today';
    } else if (moment().locale('en').add(1, 'days').isSame(date, 'day')) {
      return 'Tomorrow';
    }
    return this.getDate(date, format);
  }

  offsetToZone(): string {
    let time = this.time, zone = '';
    const prefix = time < 0 ? '-' : '+';
    time = time < 0 ? -time : time;
    zone = String(Math.floor(time / 60) < 10 ? '0' + Math.floor(time / 60) : Math.floor(time / 60));
    zone += ':' + (time % 60 < 10 ? '0' + time % 60 : time % 60 );
    return prefix + zone;
  }

  mergeDateAndTime(date: Date, time: Date): string {
    const currentDate = new Date(date).toDateString();
    const currentTime = new Date(time).toTimeString();
    return currentDate + ' ' + currentTime;
  }

  convertHijri(newDate: string): string {
    const isoFormat = this.getISODate(newDate);
    hmoment.locale('en');
    return hmoment(isoFormat, 'YYYY-MM-DDTHH:mm:ss.sssZ').format('iDD/iMM/iYYYY');
  }

  convertGregorian(newDate: string, format = 'MM/DD/YYYY'): string {
    return hmoment(newDate, 'iDD/iMM/iYYYY').format(format);
  }

  getEpochDate(date: string): Date {
    const newDate = Date.parse(date);
    return !isNaN(newDate) ? new Date(newDate) : undefined;
  }

  getHijriDateStartOptions() {
    return moment().locale('en').subtract(hijriDateConfig.startYear, 'y').toDate();
  }

  getHijriDateEndOptions() {
    return moment().locale('en').add(hijriDateConfig.endYear, 'y').toDate();
  }

  isValidHijriDate(date) {
    return moment(date).locale('en').isBetween(
      moment().locale('en').subtract(hijriDateConfig.startYear, 'y'),
      moment().locale('en').add(hijriDateConfig.endYear, 'y'));
  }

  reconstructDateIgnoringTime(date: Date): Date {
    const dateString = date.getFullYear().toString() + '-' +
      ('0' + (date.getMonth() + 1).toString()).slice(-2) + '-' +
      ('0' + date.getDate()).slice(-2);
    return new Date(Date.parse(dateString));
  }
}
