import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of as observableOf } from 'rxjs';
import { HEADER_KEYS, V2_HEADER } from '../overview-v2/document-transcription-v2/shared/constants';

export interface IFeatures {
  ENABLE_SUPPLY_GATE_FRANCHISE_SUPPORT: boolean; // Enabled for some cities
  ENABLE_SUPPLY_GATE_ADD_CAPTAIN_V2: boolean; // Already removed by Rozmin Diff Shared
  ENABLE_SUPPLY_GATE_AC_ADD_CAPTAIN_IDENTITY: boolean; // Captain creation via Identity feature
  ENABLE_SG_NIC_VALIDATION: boolean; // Enabled for some cities
  ENABLE_SG_PROCEED_USER_ON_NIC_VALIDATION: boolean; // Enabled for some cities
  ENABLE_SG_NIC_SHOW_DUPLICATE_PROFILES: boolean; // Enabled for some cities
  ENABLE_SG_ADD_VEHICLE: boolean; // Enabled for some cities
  ENABLE_SG_ADD_SUPPLIER: boolean; // Enabled for some cities
  ENABLE_SG_SUPPORT_TICKET_MANAGEMENT: boolean; // Enabled for some cities
  ENABLE_SG_SUPPORT_DISPOSITION_MANAGEMENT: boolean; // Enabled for some cities
  ENABLE_SG_CITY_DOCUMENTS_CONFIGURATION: boolean; // Enabled for some cities
  ENABLE_SG_REPLICATE_EXISTING_CITY_ONLY: boolean; // WIP
  ENABLE_SG_SUPPORT_FILE_UPLOAD_TO_S3: boolean; // WIP (Monitoring)
  ENABLE_SG_BYPASS_DOCUMENT_EXPIRY: boolean; // Enabled for some countries
  ENABLE_SG_CAMERA_FOR_DOCUMENT_UPLOAD: boolean; // Enabled for Web Cam
  ENABLE_SUPPLY_GATE_AC_PROFILE_EDIT_POST_EXPORT: boolean; //Enabled for some cities
}

interface IConfig {
  featureToggleKey: string;
  toggleState: boolean | number;
}

@Injectable()
export class FeatureService {
  features: IFeatures;
  featureByCityInitialized$: BehaviorSubject<number> = new BehaviorSubject(null);
  featureInitialized$ = new BehaviorSubject(null);
  private readonly ngHttpClientWithoutInterceptor: HttpClient;
  headers: {[key: string]: any} = {};
  get selectedCityId() {
    return Number(localStorage.getItem('sg_city_id'));
  }

  constructor(
    private readonly httpClient: HttpClient,
    httpBackend: HttpBackend
  ) {
    this.ngHttpClientWithoutInterceptor = new HttpClient(httpBackend);
    this.headers = {
      [V2_HEADER]: '1',
      [HEADER_KEYS['auth-token-update']]: '1',
      [HEADER_KEYS['sg-auth-token']]: '1',
      ['x-city-id']: localStorage.getItem('sg_city_id') || '',
    };
  }

  fetchMergedConfigByCity(cityId?: number): Observable<IFeatures> {
    if (!cityId) cityId = Number(localStorage.getItem('sg_city_id'));
    if (isNaN(cityId)) throw new Error(`Error fetching mergedConfig: missing cityId`);
    return this.httpClient.get('/feature-toggles/merged', { headers: this.headers }).pipe(
      tap((res: any) => {
        this.features = this.convertArrayToObject(res.data);
        this.featureByCityInitialized$.next(cityId);
      }),
      map(() => this.features));
  }

  getMergedConfig(): Observable<IFeatures> {
    return (this.featureByCityInitialized$ as Observable<number>).pipe(
      first(),
      switchMap(cityId => {
        const hasFeaturesForSelectedCity = cityId === this.selectedCityId;
        if (hasFeaturesForSelectedCity) return observableOf(this.features);
        return this.fetchMergedConfigByCity();
      }));
  }

  private convertArrayToObject(res: IConfig[]): IFeatures {
    return res
      .reduce((obj, config) => {
        obj[config.featureToggleKey] = config.toggleState;
        if (config.featureToggleKey === 'ENABLE_SUPPLY_GATE_ADD_CAPTAIN_V2') obj[config.featureToggleKey] = true;
        return obj;
      }, { }) as IFeatures;
  }

}
