import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation } from '@angular/core';

import { CarCheckService } from '../../car-check/car-check.service';
import { fileSizes, ToastrMessages } from '../constants';
import { ToastrService } from '../toastr/toastr.service';

@Component({
  selector: 'file-uploader',
  templateUrl: './file-uploader.template.html',
  encapsulation: ViewEncapsulation.Emulated,
  styleUrls: ['./file-uploader.style.scss']
})

export class FileUploaderComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input('options') options: any;
  @Input('file') file: any;
  @Output() upload: any = new EventEmitter();
  @Output() preview: any = new EventEmitter();
  isPdf: boolean;

  constructor(
    private readonly toastrService: ToastrService,
    private readonly carCheckService: CarCheckService
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {}

  onFileUpload(event: any) {
    if (event.target.files) {
      const file: File = event.target.files[0];
      if (file && !this.carCheckService.isValidFile(file.type)) {
        this.toastrService.openToastr(ToastrMessages.supportedFormats, ToastrMessages.error);
      } else if (file?.size > fileSizes?.maxLimit) {
        this.toastrService.openToastr(ToastrMessages.fileSizeLimit, ToastrMessages.error);
      } else if (file?.type === 'application/pdf') {
        this.isPdf = true;
        this.file.current = this.file.preview = 'assets/img/pdf-icon.png';
        this.uploadFileData(file);
      } else {
        this.isPdf = false;
        this.uploadFileData(file);
      }
    }
  }

  uploadFileData(file) {
    const fileReader: FileReader = new FileReader;
    fileReader.readAsDataURL(file);
    fileReader.onloadend = (() => this.file.data = fileReader.result);
  }

  uploadImage() {
    this.upload.emit(this.file);
  }

  previewImage() {
    if (!this.isPdf) {
      this.preview.emit();
    }
  }

  clearImage() {
    this.file.data = '';
  }

  ngOnDestroy() {}
}
