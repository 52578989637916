import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Select2OptionData } from 'ng-select2';
import { Observable,  throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { CurrentStatus, UserOverview } from './overview';
import { progressValues, statusValues } from './overview.constants';



@Injectable()
export class OverviewService {
  progressStatus: any;
  statuses: any;

  constructor(
    private httpClient: HttpClient
  ) {
    this.progressStatus = progressValues;
    this.statuses = statusValues;
  }

  getLanguages(): Observable<Select2OptionData[]> {
    return this.httpClient.get('languages').pipe(
      map(res => res['data'].map(language =>
        ({ id: language.id, text: language.name }))));
  }

  checkinTraining(data: any, id: number): Observable<any> {
    return this.httpClient.post(`training/${id}/checkin`, data);
  }

  getOverviews(params: any): Observable<any> {
    const args: any = { };

    args.page = String(params.page);
    args.dateType = String(params.dateType);

    if (params.from) args.training_from = String(params.from);
    if (params.to) args.training_to = String(params.to);
    if (params.online) args.online = String(params.online);
    if (params.keyword) args.keyword = String(params.keyword);
    if (params.status) args.status = String(params.status);
    if (params.progress) args.progress = String(params.progress);
    if (params.training) args.training_id = String(params.training);
    if (params.company_id) args.company_id = String(params.company_id);
    if (params.location) args.location = String(params.location);
    args.sort_by = params.sort_by ? String(params.sort_by) : 'start_time';
    args.sort_order = params.sort_order ? String(params.sort_order) : 'desc';

    return this.httpClient.get(`overview?${new HttpParams({ fromObject: args }).toString().replace(/\+/gi, '%2B')}`).pipe(
      catchError(err => observableThrowError({})));
  }

  message(user: UserOverview, type: string): string {
    for (const status of Object.keys(this.statuses[type])) {
      if (user[type].status === this.statuses[type][status].status) {
        if (type === 'training' && this.isTrainingWithResult(user)) {
          return `${this.statuses[type][status].message} - Score ${user.training.score || 0} out of 20`;
        } else if (type === 'transcription') {
          const transcriber = user.transcriber.id ? ` - ${user.transcriber.first_name} ${user.transcriber.last_name}` : '';
          return this.statuses[type][status].message + transcriber;
        } return this.statuses[type][status].message;
      }
    }
  }

  isTrainingWithResult(user) {
    return (user.training.status === this.statuses.training.approved.status ||
      user.training.status === this.statuses.training.rejected.status);
  }

  getStatus(): Observable<any> {
    return this.httpClient.get('status');
  }

  forceTrainingStatus(action: string, id: number): Observable<any> {
    const request: any = { user_id: id };
    return this.httpClient.put(`forceaction/${action}`, request);
  }

  getCities(): Observable<Select2OptionData[]> {
    return this.httpClient.get('cities').pipe(
      map((res) => res['data'].map(city => ({ id: city.id, text: city.name }))));
  }

  getUserCities(): Observable<Select2OptionData[]> {
    return this.httpClient.get('user/cities').pipe(
      map((res) => res['data'].map(city => ({
        id: city.id,
        text: city.name,
        offset: city.timezone_time_offset_mins
      }))));
  }

  sendTrainingSMS(object: any): Observable<any> {
    return this.httpClient.post('training/sms', object);
  }

  getUserTrainings(userid: number): Observable<any> {
    const args = new HttpParams().set('user_id', userid.toString());
    return this.httpClient.get(`overview/usertraining?${args.toString().replace(/\+/gi, '%2B')}`);
  }

  getProfileStatus(userid: number): Observable<CurrentStatus> {
    const args = new HttpParams().set('user_id', userid.toString());
    return this.httpClient.get(`profilestatus?${args.toString().replace(/\+/gi, '%2B')}`)
      .pipe(map((response: any) => <CurrentStatus> response));
  }
}
