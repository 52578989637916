<div bsModal #modalWindow="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title text-xs-center fw-bold mt" id="myModalLabel18">Slot Booking</h4>
                <button (click)="modalWindow.hide()" aria-label="Close" class="close" type="button">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <form [formGroup]="slotModal" (ngSubmit)="onSubmit(slotModal.value)" novalidate>
                <div class="modal-body">
                    <div class="row">
                        <div class="form-group col-md-12">
                            <label for="location-select" class="label-header fw-semi-bold">Location</label>
                            <div *ngIf="!training_id">
                                <ng-select2 id="location-select"
                                         [value]="location"
                                         (valueChanged)="onChangeLocation($event)"
                                         name="location"
                                         [data]="locations"
                                         [options]="{theme: 'bootstrap4'}"
                                         [width]="'100%'"></ng-select2>
                            </div>
                            <div *ngIf="training_id">{{bookedSlot?.location?.title}}</div>
                            <span class="error" *ngIf="slotModal.controls['location_id'].hasError('required') && slotModal.controls['location_id'].dirty">This field is required</span>
                        </div>
                        <div class="form-group col-md-12">
                            <label for="language-select" class="label-header fw-semi-bold">Language</label>
                            <div *ngIf="!training_id">
                                <ng-select2 id="language-select" [value]="language" (valueChanged)="onChangeLanguage($event)" name="languages" [data]="languages"
                                    [options]="{theme: 'bootstrap4'}" [width]="'100%'"></ng-select2>
                            </div>
                            <div *ngIf="training_id">{{bookedSlot?.language?.name}}</div>
                            <span class="error" *ngIf="slotModal.controls['language_id'].hasError('required') && slotModal.controls['language_id'].dirty">This field is required</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-12">
                            <label for="slot-select" class="label-header fw-semi-bold">{{!training_id ? 'Available' : 'Booked'}} Slot</label>
                            <div *ngIf="!training_id">
                                <ng-select2 id="slot-select" [value]="time_slot" (valueChanged)="onChangeSlot($event)" name="slot" [data]="slots" [options]="{theme: 'bootstrap4'}"
                                    [width]="'100%'"></ng-select2>
                            </div>
                            <div *ngIf="training_id">
                                <span>{{bookedSlot?.start_time | date: 'dd MMMM yyyy hh:mma'}}</span>
                                <span>by {{bookedSlot?.trainer?.first_name}} {{bookedSlot?.trainer?.last_name}}</span>
                            </div>
                            <span class="error" *ngIf="slotModal.controls['time_slot'].hasError('required') && slotModal.controls['time_slot'].dirty">This field is required</span>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-gray" (click)="modalWindow.hide()">Close</button>
                    <button type="submit" class="btn btn-success" *ngIf="!training_id" [disabled]="!slot.training_id">Book Now</button>
                    <button type="button" class="btn btn-success" (click)="updateSlot()" *ngIf="training_id">Change Slot</button>
                </div>
            </form>
        </div>
    </div>
</div>
