import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'img-viewer',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './image-viewer.template.html'
})
export class ImageViewer implements OnInit, AfterViewInit {
  @Input() image: string;
  @Output() close: any  = new EventEmitter();
  constructor(
  ) { }

  ngOnInit() { }

  closeImage() {
    this.close.emit({});
  }

  ngAfterViewInit() {}

}
