import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Pagination } from '../';
import { Captain } from '../../download-data/captain';

@Component({
  selector: 'download-data-table',
  templateUrl: './download-data-table.template.html'
})

export class DownloadDataTableComponent implements OnInit {

  @Input('captains') captains: Array<Captain>;
  @Input('pagination') paginationObject: Pagination;
  @Input('currentPage') currentPage: Pagination;

  @Output('sortByEvent') sortByEvent = new EventEmitter<any>();
  @Output('sortOrderEvent') sortOrderEvent = new EventEmitter<any>();
  @Output('pageChangedEvent') pageChangedEvent = new EventEmitter<any>();
  @Output('currentPageEvent') currentPageEvent = new EventEmitter<any>();
  maxSize = 5;

  ngOnInit() {
  }

  onChangeSortBy(event) {
    this.sortByEvent.emit(event);
  }


  onChangeSortOrder(event) {
    this.sortOrderEvent.emit(event);
  }


  pageChanged(event) {
    this.pageChangedEvent.emit(event);
  }


  onModelChange(event) {
    this.currentPageEvent.emit(event);
  }



}
