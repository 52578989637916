import { AfterViewInit, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { forkJoin as observableForkJoin, Observable, Subject } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { CarCheckService } from '../../../car-check/car-check.service';
import { DynamicModalService } from '../dynamic-modal/dynamic-modal.service';

import { Car } from '../../../car-check/data';

@Component({
  selector: 'check-car',
  templateUrl: './check-car.template.html'
})

export class CheckCarModalComponent implements OnInit, AfterViewInit {
  @ViewChild('modalWindow') checkCar;

  cars: Array<Car>;
  status: { approve?: number; reject?: number } = {};
  statuses: Array<{ plate: string; request: Observable<any>}  > = [];
  onClose: Subject<any>;

  constructor(
    private dynamicModalService: DynamicModalService,
    private carCheckService: CarCheckService,
    private injector: Injector
  ) {
    this.status.approve = 2;
    this.status.reject = 3;
  }

  ngOnInit() {
    this.cars = this.injector.get('cars');
    this.onClose = this.injector.get('onClose');
  }

  ngAfterViewInit() {
    this.checkCar.show();
    this.checkCar.onHidden
      .subscribe(() => this.dynamicModalService.modalOnClose.next(null));
  }

  setStatus(car: Car, status: number) {
    car.status.id = status;
    car.status.title = status === 3 ? 'Rejected' : 'Approved';

    const request = { status_id: String(car.status.id), id: car.id };
    this.statuses = this.statuses.filter((request) => request.plate !== car.registration);
    this.statuses.push({ request: this.carCheckService.checkCar(request), plate: car.registration });
  }

  updateCar(car) {
    const request = { status_id: car.status.id, id: this.injector.get('user_id') };
    this.carCheckService.checkCar(request).pipe(first(),
      map(res => res['data'])).subscribe(() => {});
  }

  updateCars() {
    if (!this.statuses.length) {
      this.checkCar.hide();
    } else {
      observableForkJoin(this.statuses.map(status => status.request)).pipe(
        first()).subscribe(() => {
        this.onClose.next({ value: this.cars[0].status.id});
        this.checkCar.hide();
      });
    }
  }
}
