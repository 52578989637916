import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { empty as observableEmpty,  Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Company, CompanyGetParams, CompanySendOtpResponse, LimoCompanyV2 } from './limo-company';
import { OtpServiceType } from '../common/global-interfaces';
import { InterceptorLoggedHttpParams, InterceptorRequestConfig } from '../common/error-logging/interceptor-http-params';
import {
  SOURCE_LIMO_COMPANY_SEARCH_SERVICE
} from '../common/error-logging/const/error-logging-sources';

@Injectable()
export class LimoCompanyService {

  readonly defaultLimit = 100;

  constructor(
    private httpClient: HttpClient
  ) { }

  getCompanies(pageNumber: number = 1, search: string = '', sort_by: string = '', sort_order = '', params?: CompanyGetParams): Observable<any> {
    let args = new HttpParams()
      .set('page', String(pageNumber))
      .set('search', String(search))
      .set('sort_order', String(sort_order))
      .set('sort_by', String(sort_by));
    if (!params) params = { };
    Object.keys(params)
      .filter(key => params[key])
      .forEach(key => args = args.set(key, params[key]));
    return this.httpClient.get(`company?${args.toString().replace(/\+/gi, '%2B')}`)
      .pipe(catchError(err => observableEmpty()));
  }

  getCompaniesV2(rsqlFilters: string, page = 1): Observable<LimoCompanyV2[]> {
    let params = new HttpParams();
    if (rsqlFilters) {
      params = params.set('filters', rsqlFilters);
    }

    const offset = (page - 1) * this.defaultLimit;
    params = params.set('offset', offset.toString());
    params = params.set('include', 'city');

    return this.httpClient.get(`supply/gate/v2/limo/list`, { params })
      .pipe(map(res => res['data']));
  }

  getCompanyById(companyId: number): Observable<Company> {
    return <any>this.httpClient.get(`company/${companyId}`) as Observable<Company>;
  }

  addLimoCompany(company: any): Observable<any> {
    return this.httpClient.post('company', company);
  }

  deleteCompany(id: number): Observable<any> {
    return this.httpClient.delete(`company/${id}`);
  }

  updateCompany(company: any, id: number): Observable<any> {
    company.solution_system_id = company.update_id ? company.update_id : company.solution_system_id;
    company.name = company.update_name ? company.update_name : company.name;
    return this.httpClient.put(`company/${id}`, company);
  }

  importCompanies(company: any): Observable<any> {
    return this.httpClient.post(`company/import`, company);
  }

  validateName(value: any, id: number): Observable<any> {
    const args = new HttpParams()
      .set('id', String(id))
      .set('name', String(value));
    return this.httpClient.get(`company/validate/name?'${args.toString().replace(/\+/gi, '%2B')}`);
  }

  validateId(value: any, id: number): Observable<any> {
    const args = new HttpParams()
      .set('id', String(id))
      .set('solution_system_id', String(value));
    return this.httpClient.get(`company/validate/id?${args.toString().replace(/\+/gi, '%2B')}`);
  }

  sendOtp(id: number, service: OtpServiceType, isAddVehicle: boolean): Observable<CompanySendOtpResponse> {
    const interceptorConf: InterceptorRequestConfig = {
      errorSource: SOURCE_LIMO_COMPANY_SEARCH_SERVICE,
      customAttributes: { limoCompanyId: id, service }
    };
    const params = new InterceptorLoggedHttpParams(interceptorConf, null );
    const url = isAddVehicle ? `company/${id}/add-vehicle/otp` : `company/${id}/otp`;
    return this.httpClient.post(url, { service }, { params })
      .pipe(map(res => <CompanySendOtpResponse>res));
  }

  verifyOtp(id: number, body: { otp: number, token: string, module_type: 'vehicle' | 'support' }) {
    const interceptorConf: InterceptorRequestConfig = {
      errorSource: SOURCE_LIMO_COMPANY_SEARCH_SERVICE,
      customAttributes: { ...body, ...{limoCompanyId: id} }
    };
    const params = new InterceptorLoggedHttpParams(interceptorConf, null );
    return this.httpClient.post(`company/${id}/verify`, body, {params});
  }

}
