<!--Modal Template START-->
<div bsModal #modalWindow="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="trainer_modal" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title text-xs-center fw-bold mt" id="myModalLabel18">{{trainer.user_id ? 'Update' : 'Add a New'}} Trainer</h4>
        <button (click)="modalWindow.hide()" aria-label="Close" class="close" type="button">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="new_trainer" novalidate (ngSubmit)="onSubmit(new_trainer.value)">
          <div class="row">
            <div class="col-md-6">
              <label for="first-name" class="label-header fw-semi-bold" for="first-name">First Name</label>
              <div class="form-group">
                <input type="text" class="form-control" placeholder="First Name" formControlName="first_name" id="first-name">
                <span class="error" *ngIf="new_trainer.controls['first_name'].touched && new_trainer.controls['first_name'].errors.required">This field is required</span>
                <span class="error" *ngIf="new_trainer.controls['first_name'].touched && new_trainer.controls['first_name'].hasError('pattern')">Provided name is invalid</span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="last-name" class="label-header fw-semi-bold">Last Name</label>
                <input type="text" class="form-control" id='last-name' formControlName="last_name" placeholder="Last Name">
                <span class="error" *ngIf="new_trainer.controls['last_name'].touched && new_trainer.controls['last_name'].errors.required">This field is required</span>
                <span class="error" *ngIf="new_trainer.controls['last_name'].touched && new_trainer.controls['last_name'].hasError('pattern')">Provided name is invalid</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="label-header fw-semi-bold">Languages</label>
                <div>
                  <ng-select2 [data]="languages"
                           [options]="select2Options"
                           [width]="'100%'"
                           [value]="language"
                           (valueChanged)="onChangeLanguage($event)"></ng-select2>
                </div>
                <div>
                </div>
                <span class="error" *ngIf="!language?.length">This field is required</span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="location-select" class="label-header fw-semi-bold">Location</label>
                <div>
                  <ng-select2 id="location-select" 
                          class="height-hot-fix"
                           [value]="location"
                           (valueChanged)="onChangeLocation($event)"
                           [data]="locations"
                           [options]="{multiple: true, theme: 'bootstrap4', disabled: trainer.upcoming_trainings > 0}"
                           [width]="'100%'"
                          ></ng-select2>
                </div>
                <span class="error" *ngIf="new_trainer.controls['locations'].hasError('required') && new_trainer.controls['locations'].dirty">This field is required</span>
                <span class="small" *ngIf="trainer.upcoming_trainings > 0">Location cannot be changed, because you currently have future slots</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="email" class="label-header fw-semi-bold" for="last-name">Email</label>
                <input type="email" [readonly]="trainer.user_id" class="form-control" formControlName="email" placeholder="Email" id="email">
                <span class="error" *ngIf="new_trainer.controls['email'].hasError('required') && new_trainer.controls['email'].dirty">This field is required<br/></span>
                <span class="error" *ngIf="new_trainer.controls['email'].touched && new_trainer.controls['email'].hasError('pattern')">Not a valid email pattern</span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="date-from" class="label-header fw-semi-bold">Join Date</label>
                <datetime formControlName="joining_date"
                          id="date-from" class="datetime"
                          [timepicker]="false"
                          [datepicker]="datePickerOptions"
                          [readonly]="trainer.user_id">

                </datetime>
                <span class="error" *ngIf="!new_trainer.controls['joining_date'].valid && new_trainer.controls['joining_date'].dirty">This field is required</span>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit"
                    class="btn btn-success"
                    [disabled]="!new_trainer.valid || !language?.length || setSubmitted">
              {{!trainer.user_id ? 'Create' : 'Update'}} Trainer
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!--Modal Template END-->
