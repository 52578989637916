/**
 * Values here are used on NewRelic as a means to filter for specific error sources
 * More details in readme for NewRelic Insights
 */

export const SOURCE_TOKEN_VALIDATION = 'SG Validate Token';

// Transcription
export const SOURCE_APPROVE_DOCUMENTS = 'SG Lead Transcription';

// Add Leads
export const SOURCE_ADD_LEAD_CAPTAIN = 'SG Add Captain (lead)';
export const SOURCE_ADD_LEAD_SUPPLIER = 'SG Add Supplier (lead)';
export const SOURCE_ADD_LEAD_VEHICLE = 'SG Add Vehicle (lead)';

// OTP
export const SOURCE_CAPTAIN_SEARCH_SERVICE = 'CaptainSearchService';
export const SOURCE_LIMO_COMPANY_SEARCH_SERVICE = 'LimoCompanySearchService';
export const SOURCE_SEND_OTP_TO_CAPTAIN = 'CaptainOtpVerification';

// Overviews
export const SOURCE_OVERVIEW_V2_CAPTAIN = 'OverviewV2 (Captain)';

// Other Services
export const SOURCE_USER_SERVICE = 'UserService';
export const SOURCE_CITIES_SERVICE = 'GetCitiesService';
export const SOURCE_MISC = 'Miscellaneous';
