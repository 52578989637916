import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface IComponentModel {
  component: any;
  inputs?: any;
}

@Injectable()
export class DynamicModalService {
  modalOnClose: Subject<any>;
  componentData: Subject<IComponentModel>;

  constructor() {
    this.modalOnClose = new Subject<any>();
    this.componentData = new Subject<any>();
  }

  setComponentData(componentData: IComponentModel): Subject<any> {
    const onClose = new Subject();
    componentData.inputs['onClose'] = onClose;
    this.componentData.next(componentData);
    return onClose;
  }
}
