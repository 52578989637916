import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Route, Router, RouterStateSnapshot } from '@angular/router';

import { PermissionService } from './permissions.service';

@Injectable()
export class PermissionGuard implements CanActivate {
  states: Array<any>;
  permissions: Array<string>;
  constructor(
    private permissionService: PermissionService, private router: Router) {
    this.states = this.permissionService.menuParser();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    this.permissions = this.permissionService.getPermissions(localStorage.getItem('token'));

    if (this.permissions.indexOf(route.data['permission']) > -1) {
      return true;
    } else if (this.states.length) {
      if (this.states[0].subMenu) {
        this.router.navigateByUrl(this.states[0].subMenu[0].link);
      } else {
        this.router.navigateByUrl(this.states[0].link);
      }
    } else {
      this.router.navigateByUrl('/error');
    }

    return false;
  }

}
