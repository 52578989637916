import { AfterViewInit, Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Select2OptionData } from 'ng-select2';
import { ModalDirective } from 'ngx-bootstrap';
import { Observable, Subject } from 'rxjs';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { ToastrMessages } from 'src/app/common/constants';

import { LanguagesService, LocationsService } from '../../';
import { Filters, QueryParams } from '../../../overview/overview';
import { OverviewService } from '../../../overview/overview.service';
import { Slot } from '../../../training-slots/slot';
import { TrainingSlotsService } from '../../../training-slots/training-slots.service';
import { DateService } from '../../date/date.service';
import { LocationTypeEnum } from '../../global-interfaces';
import { SpinnerService } from '../../spinner/spinner.service';
import { ToastrService } from '../../toastr/toastr.service';

@Component({
    selector: 'book-slot-modal',
    templateUrl: './book-slot.template.html'
})

export class BookSlotModalComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('modalWindow') modelWindow: ModalDirective;
  newSlot: Date = new Date();
  slotModal: FormGroup;
  location: number;
  captain_type_id: number;
  training_id: number;
  onClose: Subject<void>;
  bookedSlot: Slot;
  slots: Array<Select2OptionData> = [];
  slot: {training_id: number, user_id: number} = {
    training_id: 0,
    user_id: 0
  };
  locations: Array<Select2OptionData> = [];
  languages: Array<Select2OptionData> = [];

  filter: Filters = {};

  constructor(
    private overviewService: OverviewService,
    private formBuilder: FormBuilder,
    private locationService: LocationsService,
    private toastrService: ToastrService,
    private spinnerService: SpinnerService,
    private trainingSlotService: TrainingSlotsService,
    private languageService: LanguagesService,
    private dateService: DateService,
    private injector: Injector
  ) {
    this.filter.startDate = this.dateService.getStartOfDay(new Date(), true);
    this.filter.endDate = this.dateService.getEndOfDay(new Date(), true);
  }

  ngOnInit() {
    this.slot.user_id = this.injector.get('user_id');
    this.training_id = this.injector.get('training_id');
    this.captain_type_id = this.injector.get('captain_type_id');
    this.onClose = this.injector.get('onClose');

    this.initializeForm();

    if (this.training_id) {
      this.trainingSlotService.getSlot(this.training_id)
        .subscribe((slot: any) => {
          this.bookedSlot = slot;
          this.bookedSlot.start_time = this.dateService.getDate(this.bookedSlot.start_time, null, true);
        },
          () => this.updateSlot());
    } else {
      this.updateSlot();
    }
  }

  ngAfterViewInit() {
    this.modelWindow.show();
  }

  initializeForm() {
    this.slotModal = this.formBuilder.group({
      training_id: ['', Validators.required],
      user_id: [''],
      location_id: [''],
      language_id: [''],
      datetime: [new Date()],
      time_slot: ['']
    });

    this.slotModal.valueChanges.pipe(
      filter(value => (value.location_id && value.language_id)),
      mergeMap(() => this.getTrainingSlots()))
      .subscribe((slots: any) => {
        if (slots.length === 0) {
          this.toastrService.openToastr(ToastrMessages.noSlots, ToastrMessages.error);
        } this.slots = <Select2OptionData[]> slots;
      });
  }

  updateSlot() {
    this.training_id = null;
    this.initializeForm();

    this.languageService.getActiveLanguages()
      .subscribe(languages => this.languages = languages);

    this.locationService.getLocations(LocationTypeEnum.Captain)
      .subscribe((locations) => this.locations = locations);
  }

  onChangeSlot(slot: string) {
    this.slot.training_id = Number(slot);
  }

  onChangeLocation(event: string) {
    this.slotModal.controls['location_id'].setValue(event);
  }

  onChangeLanguage(event: string) {
    this.slotModal.controls['language_id'].setValue(event);
  }

  getTrainingSlots(): Observable<Slot[]> {
    const params: QueryParams = {
      language_id: this.slotModal.get('language_id').value,
      captain_type_id: this.captain_type_id,
      location_id: this.slotModal.get('location_id').value
    };

    return this.trainingSlotService.getAvailableTrainings(params).pipe(
      map((res => this.mapSlots(res['data']))));
  }

  onSubmit(form: Form) {
    this.spinnerService.change(true);
    this.trainingSlotService.bookSlot(this.slot).pipe(
      tap(() => {
        this.onClose.next();
        this.spinnerService.change(false);
      }))
      .subscribe(() => this.modelWindow.hide());
  }

  mapSlots(slots: Slot[]): Array<Slot> {
    return slots.map((slot: any) =>
      ({ id: slot.id, text: this.dateService.getDate(slot.start_time, 'DD MMM YYYY, hh:mm A', true)}));
  }

  ngOnDestroy() {}
}
