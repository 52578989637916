
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, mergeMap, tap } from 'rxjs/operators';

import { GetCitiesService } from '../get-cities.service';
import { SpinnerService } from '../spinner/spinner.service';

import { AuthService } from './auth.service';
import { from } from 'rxjs';

export interface LoginPayload {
    email: string;
    password: string;
}

@Injectable()
export class AuthHelper {

    constructor(
        private readonly authService: AuthService,
        private readonly router: Router,
        private readonly citiesService: GetCitiesService,
        private readonly spinnerService: SpinnerService
    ) { }

    initLogin() {
        from(this.authService.initOAuthFlow())
            .pipe(
                tap(() => this.spinnerService.change(true)),
                mergeMap(redirectUri => this.citiesService.getCities().pipe(map((cities: any) => ({ cities, redirectUri }))))
            )
            .subscribe(({ cities, redirectUri }) => {
                this.spinnerService.change(false);
                if (!this.authService.getCityId()) {
                    this.authService.setCityId(String(cities[0].id));
                }
                this.citiesService.citiesList(cities);
                this.router.navigate([redirectUri.path], { queryParams: redirectUri.queryParams });
            }, error => {
                this.spinnerService.change(false);
                this.router.navigate(['/auth-error'], { queryParams: { message: error } });
            });
    }

}
