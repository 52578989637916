<!--Modal Template START-->
<div bsModal #modalWindow="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="add_limo_company_modal" aria-hidden="true">
    <div class="modal-dialog">
        <form [formGroup]="new_limo" novalidate (ngSubmit)="onSubmit(new_limo.value)">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title text-xs-center fw-bold mt" id="myModalLabel18">Add Limo Company</h4>
                    <button (click)="modalWindow.hide()" aria-label="Close" class="close" type="button">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row pad-t-b-50">


                        <div class="col-lg-6 col-md-6 col-xs-12">
                            <div class="form-group">
                                <label class="label-header fw-semi-bold" for="solution_system_id">Limo Company
                                    ID</label>
                                <input type="text" class="form-control"
                                       formControlName="solution_system_id"
                                       id="solution_system_id" placeholder="Limo Company ID">
                                <span class="error"
                                      *ngIf="new_limo.controls['solution_system_id'].touched && new_limo.controls['solution_system_id'].errors.required">This field is required</span>
                                <span class="error"
                                      *ngIf="new_limo.controls['solution_system_id'].touched && new_limo.controls['solution_system_id'].hasError('pattern')">Provided id is invalid it should be numeric.</span>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-xs-12">
                            <label for="name" class="label-header fw-semi-bold" for="name">Name</label>

                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Name"
                                       formControlName="name" id="name">
                                <span class="error"
                                      *ngIf="new_limo.controls['name'].touched && new_limo.controls['name'].errors.required">This field is required</span>
                                <span class="error"
                                      *ngIf="new_limo.controls['name'].touched && new_limo.controls['name'].hasError('pattern')">Provided it contains invalid characters</span>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="modal-footer">
                    <button (click)="modalWindow.hide()" aria-label="Close" class="btn btn-default" type="button">
                        <span aria-hidden="true">Cancel</span>
                    </button>
                    <button type="submit"
                            class="btn btn-success"
                            [disabled]="!new_limo.valid">
                            Create Company
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
<!--Modal Template END-->
