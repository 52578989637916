<!--Modal Template START-->
<div bsModal #modalWindow="bs-modal" (onHide)="hideModal()" [config]="{backdrop: false}" class="modal fade prompt-modal"
  tabindex="-1" role="dialog" aria-labelledby="import_limo_company_modal" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header text-capitalize">
        <h4 class="modal-title text-xs-center fw-bold mt" id="myModalLabel18">{{title}}</h4>
        <button (click)="closeModal()" aria-label="Close" class="close" type="button">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <p>{{message}}</p>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-success btn-sm text-uppercase" (click)="closeModal(true)">
          {{confirmButton}}
        </button>
        <button *ngIf="cancelButton" type="button" class="btn btn-default btn-sm text-uppercase" (click)="closeModal(false)">
          {{cancelButton}}
        </button>
      </div>
    </div>
  </div>
</div>
<!--Modal Template END-->
