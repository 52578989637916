import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { DataTableModule } from '@pascalhonegger/ng-datatable';
import { NKDatetimeModule } from 'ng2-datetime/ng2-datetime';
import { NgSelect2Module } from 'ng-select2';
import { AlertModule, TooltipModule, BsDropdownModule,
  ButtonsModule, ModalModule, PaginationModule } from 'ngx-bootstrap';
import { WebcamModule } from 'ngx-webcam';

import { CarCheckService } from '../car-check/car-check.service';
import { OverviewService } from '../overview/overview.service';
import { TrainerManagementService } from '../trainer-management/trainer-management.service';
import { TrainingSlotsService } from '../training-slots/training-slots.service';
import { UserManagementService } from '../user-management/user-management.service';

import { LanguagesService, LocationsService, TrainersService, UsersService } from './';
import { CountriesService } from './countries.service';
import { DownloadDataTableComponent } from './download-data-table/download-data-table.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { ImageViewer } from './image-viewer/image-viewer.component';
import { AddLimoCompanyModalComponent } from './modals/add-limo-company/add-limo-company.component';
import { AddTrainerModalComponent } from './modals/add-trainer/add-trainer.component';
import { AddUserModalComponent } from './modals/add-user/add-user.component';
import { BookSlotModalComponent } from './modals/book-slot/book-slot.component';
import { ChangeTrainingModal } from './modals/change-training/change-training.component';
import { CheckCarModalComponent } from './modals/check-car/check-car.component';
import { TrainingSlotModalComponent } from './modals/create-training-slot/training-slot.component';
import { DeleteLimoCompanyModalComponent } from './modals/delete-limo-company/delete-limo-company.component';
import { ImportLimoCompanyModalComponent } from './modals/import-limo-company/import-limo-company.component';
import { LimoImportErrorModalComponent } from './modals/limo-import-error/limo-import-error.component';
import { MessagePopupComponent } from './modals/message-popup/message-popup.component';
import { PromptComponent } from './modals/prompt/prompt.component';
import { SupplierDetailsModalComponent } from './modals/supplier-details/supplier-detais.component';
import { SpinnerComponent } from './spinner/spinner';
import { ValidateEqualDirective } from './validate-equal/validate-equal.directive';
import { NotEqualDirective } from './validation/not-equal.directive';
import { SwitchButtonComponent } from './switch-button/switch-button.component';
import { CameraModalComponent } from './modals/camera/camera.component';

@NgModule({
    providers: [
        TrainersService,
        UsersService,
        LocationsService,
        LanguagesService,
        OverviewService,
        TrainingSlotsService,
        TrainerManagementService,
        CountriesService,
        UserManagementService,
        CarCheckService
    ],
    declarations: [
        BookSlotModalComponent,
        AddTrainerModalComponent,
        AddUserModalComponent,
        MessagePopupComponent,
        TrainingSlotModalComponent,
        AddLimoCompanyModalComponent,
        ImageViewer,
        ImportLimoCompanyModalComponent,
        DeleteLimoCompanyModalComponent,
        ChangeTrainingModal,
        LimoImportErrorModalComponent,
        CheckCarModalComponent,
        FileUploaderComponent,
        SupplierDetailsModalComponent,
        PromptComponent,
        NotEqualDirective,
        SpinnerComponent,
        ValidateEqualDirective,
        DownloadDataTableComponent,
        CameraModalComponent,
        SwitchButtonComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        NKDatetimeModule,
        ReactiveFormsModule,
        AlertModule.forRoot(),
        PaginationModule.forRoot(),
        ConfirmationPopoverModule.forRoot({
            confirmButtonType: 'success'
        }),
        ModalModule.forRoot(),
        ButtonsModule.forRoot(),
        DataTableModule,
        BsDropdownModule.forRoot(),
        TooltipModule.forRoot(),
        WebcamModule,
        NgSelect2Module
    ],
    exports: [
        SpinnerComponent,
        ValidateEqualDirective,
        DownloadDataTableComponent,
        ReactiveFormsModule,
        FormsModule,
        SwitchButtonComponent
    ]
})
export class AppCommonModule {
}
