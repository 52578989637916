import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HEADER_KEYS, V2_HEADER } from '../overview-v2/document-transcription-v2/shared/constants';

@Injectable()
export class TrainerManagementService {
  private readonly headers;

  constructor(
    private readonly httpClient: HttpClient
  ) {
    this.headers = {
      [V2_HEADER]: '1',
      ['sg-user-city']: localStorage.getItem('sg_city_id') || '',
      ['x-city-id']: localStorage.getItem('sg_city_id') || '',
      [HEADER_KEYS['auth-token-update']]: '1',
      [HEADER_KEYS['sg-auth-token']]: '1',
    };
  }

  getTrainers(): Observable<any> {
    return this.httpClient.get('/trainings/trainer/list', { headers: this.headers });
  }

  getAllTrainers(languageId?: number, locationId?: number) {
    const args: any = {};
    if (locationId) {
      args.location_id = String(locationId);
    }
    if (languageId) {
      args.lang_id = String(languageId);
    }
    return this.httpClient.get('/trainings/trainer/list', { headers: this.headers, params: new HttpParams({ fromObject: args }) });
  }

  addTrainer(trainer: any): Observable<any> {
    return this.httpClient.post('trainer', trainer);
  }

  deleteTrainer(id: number): Observable<any> {
    return this.httpClient.delete(`trainer/${id}`);
  }

  updateTrainer(trainer: any, id: number): Observable<any> {
    return this.httpClient.put(`trainer/${id}`, trainer);
  }
}
