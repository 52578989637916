import { Injectable } from '@angular/core';
import { CanActivate, Router  } from '@angular/router';

import { PermissionService } from '../permissions/permissions.service';

@Injectable()
export class RedirectGuard implements CanActivate {

  constructor(
    private router: Router,
    private permissionService: PermissionService
  ) { }

  canActivate() {
    return true;
  }
}
