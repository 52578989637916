import { Injectable } from '@angular/core';
import { intersection } from 'lodash';

import { AuthService } from '../auth/auth.service';
import { FRANCHISE_ROLES } from '../../common/constants';
import { permissionsConstant } from './permissions.constant';
import { sideBarMenu } from './sidebar-menu.constant';


@Injectable()
export class PermissionService {

  constructor(
    private readonly authService: AuthService
  ) { }

  getUserRoles() {
    const rolesArr = this.authService.getUserRoles();
    return rolesArr.map(role => role['name']);
  }

  getAllRights() {
    const rolePermissions = this.getPermissions(localStorage.getItem('token'));
    return [].concat.apply([], rolePermissions)
      .reduce((a, b) => {
        if (a.indexOf(b) < 0) a.push(b);
        return a;
      }, []);
  }

  menuParser() {
    const menu = this.filteredMenu(sideBarMenu);
    menu.map(menuItem => {
      if (menuItem.subMenu) {
        menuItem.subMenu = this.filteredMenu(menuItem.subMenu);
      }
      return menuItem;
    });
    return menu;
  }

  filteredMenu(menu) {
    return menu.filter((item: any) => {
      if (!item.right || this.isUserAuthorized(item.right)) {
        return item;
      }
    });
  }

  isUserAuthorized(right) {
    return this.getAllRights().indexOf(right) > -1;
  }

  isFranchiseUser() {
    const roles = this.getUserRoles();
    return !!intersection(FRANCHISE_ROLES, roles).length;
  }

  isPathAuthorized(path) {
    const obj = permissionsConstant.filter((item: any) => {
      return item.path === path || item.path === path.split('?')[0];
    });
    if (obj[0] && obj[0]['right']) {
      return this.isUserAuthorized(obj[0]['right']);
    }
    return false;
  }

  getPermissions(token): Array<string> {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const data = JSON.parse(window.atob(base64));
    /**
     * This check is to handle invalid tokens which is already in localstorage.
     * this will check if token has permission
     * otherwise it will logout the user.
     */
    if (data.permissions) {
      return data.permissions;
    } else {
      this.authService.logout();
    }
    return null;
  }
}
