import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService
  ) {}

  canActivate() {
    if (this.authService.getToken() && this.authService.getCityId()) {
      return true;
    }

    this.authService.redirectToLogin();
    return false;
  }
}
