import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Select2OptionData } from 'ng-select2';
import { ModalDirective } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { ToastrMessages } from 'src/app/common/constants';

import { DateService, ToastrService } from '../../';
import { Slot } from '../../../training-slots/slot';
import { TrainingSlotsService } from '../../../training-slots/training-slots.service';


@Component({
  selector: 'change-training',
  templateUrl: './change-training.template.html'
})

export class ChangeTrainingModal implements OnInit {
  @ViewChild('modalWindow') modalWindow: ModalDirective;
  confirmation = false;
  form: FormGroup;
  canLoadMore = false;
  onClose: Subject<void>;
  selectedTraining: Slot;
  list: Array<Slot>;
  availableTrainings: Array<Select2OptionData> = [];
  trainings: Array<Slot> = [];
  slot: Slot;
  to_training_id: any;

  constructor(
    private formBuilder: FormBuilder,
    private trainingSlotService: TrainingSlotsService,
    private dateService: DateService,
    private toastrService: ToastrService,
    private injector: Injector
  ) {}

  ngOnInit() {
    this.slot = this.injector.get('information');
    this.onClose = this.injector.get('onClose');
    this.list = this.injector.get('trainings');
    this.availableTrainings = this.list.length > 0 ? this.mapSlots(this.list) : [];
    this.form = this.formBuilder.group({
      to_training_id: [this.availableTrainings.length ? this.availableTrainings[0].id : '', [Validators.required]]
    });
  }

  ngAfterViewInit() {
    this.modalWindow.show();
  }

  confirm() {
    this.confirmation = true;
    this.modalWindow.hide();
  }

  onChangeSlot(training_id: any = 0) {
    this.form.patchValue({to_training_id: training_id});
    this.selectedTraining = this.trainings.find(training => training.id === Number(training_id));
    if (this.selectedTraining) {
      this.selectedTraining.available_slots = this.selectedTraining.capacity - this.selectedTraining.attendees_count;
    }
  }

  moveSlot() {
    this.trainingSlotService.moveSlotAndDelete(
      this.slot.training_id,
      this.form.get('to_training_id').value
      ).subscribe((res: any) => {
        if (!res.bookedSlotsStats) {
          this.toastrService
            .openToastr(ToastrMessages.userMovedSuccess, ToastrMessages.success);
          this.closeModal();
        } else {
          this.slot.attendees_count = res.bookedSlotsStats.bookings_left;
          this.availableTrainings = this.availableTrainings.filter((training) =>
            Number(training.id) !== Number(this.selectedTraining.id));
          this.toastrService
            .openToastr(`Still pending (${this.slot.attendees_count}) attendees to be assigned. Cannot delete training`, 'error');
        }
      });
  }

  closeModal() {
    this.onClose.next();
    this.modalWindow.hide();
  }

  mapSlots(slots: any): Array<any> {
    return slots
      .map(slot => ({ id: slot.id, text: this.dateService.getDate(slot.start_time, 'DD MMM YYYY, hh:mm A', true) }))
      .filter(slot => String(slot.id) !== String(this.slot.training_id));
  }
}
