import { AfterViewInit, Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Select2OptionData } from 'ng-select2';
import { Subject } from 'rxjs';

import { Company } from '../../../limo-company/limo-company';
import { LimoCompanyService } from '../../../limo-company/limo-company.service';

@Component({
  selector: 'import-limo-company',
  templateUrl: 'import-limo-company.template.html',
  providers: [LimoCompanyService]
})

export class ImportLimoCompanyModalComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('modalWindow') limoImportModal;
  locations: Array<Select2OptionData>;
  isFormSubmitted = false;
  company: Company;
  languages: Array<Select2OptionData>;
  language: Array<any>;
  onClose: Subject<any>;
  isFileValid = true;
  isFileSelect = true;
  fileName: any = false;
  file_data: any;
  formats: Array<string> = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel'
  ];

  select2Options: Select2Options = {
    multiple: true,
    theme: 'bootstrap4'
  };

  /**
   * Component Constructor.
   * @param formBuilder
   * @param injector
   */
  constructor(
    private injector: Injector
  ) {

  }



  /**
   * Init
   */
  ngOnInit() {
    this.onClose = this.injector.get('onClose');
  }


  /**
   * After modal is initialized.
   */
  ngAfterViewInit() {
    this.limoImportModal.show();
  }


  /**
   * Listens file change.
   * @param $event
   */
  onFileUpload($event) {
    this.uploadAsBase64($event.target);
  }

  /**
   * Generate base 64 of the file.
   * @param inputValue
   */
  uploadAsBase64(inputValue: any): void {
    const file: File = inputValue.files[0];
    const limoFileReader: FileReader = new FileReader();
    limoFileReader.readAsDataURL(file);
    limoFileReader.onloadend = (e) => {
      if (this.isValidFile(file.type)) {
        this.fileName = file.name;
        const fileReaderResult = <string>limoFileReader.result;
        this.file_data =  {
          file: fileReaderResult.substring(fileReaderResult.indexOf(',') + 1)
        };
        this.isFileValid = true;
        this.isFileSelect = true;
        this.isFormSubmitted = true;
      } else {
        this.fileName = false;
        this.isFileValid = false;
        this.isFileSelect = true;
        this.isFormSubmitted = false;
      }
    };
  }


  upload() {
    this.closeModal(this.file_data);
  }




  /**
   * Close modal.
   * @param data
   */
  closeModal(data: any) {
    this.limoImportModal.hide();
    this.onClose.next(data);
    this.onClose.complete();
  }



  /**
   * On destroy event.
   */
  ngOnDestroy() {
  }


  isValidFile(fileType: string) {
    return (this.formats.indexOf(fileType) > -1);
  }

}
