
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class UsersService {

  constructor(
    private httpClient: HttpClient
  ) {}

  getUsers(): Observable<any> {
    return this.httpClient.get('users');
  }

  getCaptain(): Observable<any> {
    return this.httpClient.get('captain-types').pipe(
    map((res) => res['data'].map(type => ({
        id: type.id,
        text: type.title_key
      }))));
  }

  addCaptain(captain: any): Observable<any> {
    return this.httpClient.post('captain', captain);
  }
}
