<!--Modal Template START-->
<div bsModal #modalWindow222="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="import_limo_company_modal" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header error-modal">
          <h4 class="modal-title text-xs-left fw-bold" id="myModalLabel18">Upload Error</h4>

      </div>
      <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 error-modal-padding">
              <h5 class="text-danger">XLSX failed to upload due to following errors, please rectify the below mentioned errors in Excel file before attempting to upload again.</h5>
              <br>
              <br>
              <h6 class="text-danger" *ngIf="data.errors[0].source.column == '*' || data.errors[0].source.row == '0' ">{{data.errors[0].title}}</h6>
              <table class="table table-bordered" *ngIf="data.errors[0].source.column != '*' || data.errors[0].source.row != '0' ">
                <caption></caption>
                  <thead>
                  <th scope="col">Row#</th>
                  <th scope="col">Error Details</th>
                  </thead>
                  <tbody>
                  <tr *ngFor="let item of data.errors ">
                      <td class="error-modal-td-id">{{item.source.row}}</td>
                      <td>{{item.title}}</td>
                  </tr>
                  </tbody>
                </table>
            </div>
          </div>
      </div>
      <div class="modal-footer text-right">
        <button type="button"
                class="btn btn-success btn-sm text-uppercase" (click)="closeModal()">
          OK
        </button>
      </div>
    </div>
  </div>
</div>
<!--Modal Template END-->
