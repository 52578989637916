import { HttpEvent, HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { StorageService } from '../storage.service';

@Injectable()
export class CityInterceptor implements HttpInterceptor {

  constructor(private readonly storageService: StorageService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.startsWith('http')) return next.handle(request);

    let cityId = String(1);

    if (this.storageService.get('allowed_cities')) {
      const allowedCities = JSON.parse(this.storageService.get('allowed_cities'));
      cityId = allowedCities[0];
    }

    if (this.storageService.get('sg_city_id')) {
      cityId = this.storageService.get('sg_city_id');
    }

    request = request.clone({
      headers: request.headers.set('X-CITY-ID', cityId)
    });

    return next.handle(request);
  }
}
