import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { galileo } from '@galileo/galileo-web-sdk';
import { AuthService } from 'src/app/common/auth/auth.service';
import { PROJECT_NAME, SG_GALILEO_VARIABLES } from 'src/app/common/constants';
import { isDevelopmentEnv } from './utils';


@Injectable()
export class ExperimentationService {

  private galileo;

  constructor(
    private authService: AuthService,
  ) {
    this.initialize();
  }

  private initialize() {
    this.galileo = galileo;
    const env = isDevelopmentEnv(window?.location?.hostname) ? 'dev' : 'prd';
    this.galileo.setEnvironment(env);
  }

  private getBool(variable: string, tags: Record<string, any>, defaultValue: boolean) {
    const response = this.galileo.getBool(variable, tags, defaultValue);
    return from(response);
  }

  private getString(variable: string, tags: Record<string, any>, defaultValue: string) {
    const response = this.galileo.getString(variable, tags, defaultValue);
    return from(response);
  }

  private getNumber(variable: string, tags: Record<string, any>, defaultValue: number) {
    const response = this.galileo.getNumber(variable, tags, defaultValue);
    return from(response);
  }

  private getObject(variable: string, tags: Record<string, any>, defaultValue: Record<string, any>) {
    const response = this.galileo.getObject(variable, tags, defaultValue);
    return from(response);
  }

  showTranscriptionRevampVersion(captainId: number) {
    const user = this.authService.getUserInfo();
    const tags = this.setTags(user, captainId, null);
    return this.getBool(`${PROJECT_NAME}/${SG_GALILEO_VARIABLES.DOCUMENT_TRANSCRIPTION_FLOW_REVAMP}`, tags, false);
  }

  userManagementRevampVersion(captainId: number | null = null) {
    const user = this.authService.getUserInfo();
    const tags = this.setTags(user, captainId, null);
    return this.getBool(`${PROJECT_NAME}/${SG_GALILEO_VARIABLES.user_management_revamp}`, tags, false);
  }

  addLeadRevampVersion(captainId: number | null = null) {
    const user = this.authService.getUserInfo();
    const tags = this.setTags(user, captainId, null);
    return this.getBool(`${PROJECT_NAME}/${SG_GALILEO_VARIABLES.add_lead_revamp}`, tags, false);
  }

  public setTags(user, captainId, cityId) {
    return {
      city: cityId,
      language: user?.language?.code,
      user: user?.id,
      captain: captainId,
      supply_gate_city: +(localStorage.getItem('sg_city_id')),
    };
  }
}
