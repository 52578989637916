<div bsModal #modalWindow="bs-modal" class="modal fade supplier-modal" tabindex="-1" role="dialog" aria-labelledby="trainer_modal"
     aria-hidden="true">
  <div class="modal-dialog supplier-modal">
    <div class="modal-content">
      <div class="modal-body">
        <div class="wrapper personal-details">
          <form class="row" [formGroup]="supplierForm">

            <h3 class="col-md-12">Supplier Personal Details</h3>

            <div class="form-group col-md-12">
              <label class="content-heading">Name</label>
              <input type="text" class="content-info form-control" formControlName="name" placeholder="Not available" readonly>
            </div>

            <div class="form-group col-md-12">
              <label class="content-heading">Mobile Number</label>
              <input type="text" class="content-info form-control" formControlName="mobile_number" placeholder="Not available" readonly>
            </div>

            <h3 class="col-md-12">Car Details</h3>

            <div class="form-group col-sm-6">
              <label class="content-heading">Car Plate Number</label>
              <input type="text" class="content-info form-control" formControlName="registration" placeholder="Not available" readonly>
            </div>

            <div class="form-group col-sm-6">
              <label class="content-heading">Car Make</label>
              <input type="text" class="content-info form-control" formControlName="make" placeholder="Not available" readonly>
            </div>

            <div class="form-group col-sm-6">
              <label class="content-heading">Car Model</label>
              <input type="text" class="content-info form-control" formControlName="model" placeholder="Not available" readonly>
            </div>

            <div class="form-group col-sm-6">
              <label class="content-heading">Car Color</label>
              <input type="text" class="content-info form-control" formControlName="color" placeholder="Not available" readonly>
            </div>

            <div class="form-group col-sm-12">
              <label class="content-heading">Car Year</label>
              <input type="text" class="content-info form-control" formControlName="year" placeholder="Not available" readonly>
            </div>

            <div class="form-group col-sm-12">
              <file-uploader [options]="{displayName: 'Car Check Form', confirmation: { ask: true, message: 'Are you sure you want to remove this file?' }}"
                             [file]="file"
                             (preview)="previewImage = true"
                             (upload)="upload($event)"></file-uploader>
            </div>

            <div class="form-group col-sm-12">
              <div class="abc-checkbox abc-checkbox-success col-sm-6" *ngFor="let status of statuses">
                <input id="checkbox-{{status.value}}"
                       type="checkbox"
                       (change)="changeStatus(status.value)"
                       [disabled]="status.value === supplierForm.get('status').value"
                       [checked]="supplierForm.get('status').value === status.value">
                <label for="checkbox-{{status.value}}">
                  {{status.display}}
                </label>
              </div>
            </div>
          </form>

          <div class="custom-steps status-message" *ngIf="car?.status?.id !== 1">
            <h3 class="h3 {{car?.status?.id === 2 ? 'green' : 'red'}} text-center"><strong class="text-capitalize">This Car has been {{car?.status?.title}}</strong>
            </h3>
          </div>

          <div class="fixd-btn">
            <button type="button" [disabled]="uploadingFile" (click)="checkCar()" class="btn btn-default">Submit</button>
            <button type="button" (click)="closeModal()" class="btn btn-default">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <img-viewer *ngIf="previewImage" (close)="previewImage = false" [image]="file.data"></img-viewer>
</div>

