export const API_V2 = 'v2-api';
export const V2_HEADER = 'v2';
export const QA_HOST = 'qa-sg3-admin.careem-internal.com';
export const LOCAL_HOST = 'localhost';

export enum MODULE_TYPE {
  captain = 'captain',
  vehicle = 'vehicle',
};

export enum DOCUMENT_TYPE {
  CAPTAIN = 'CAPTAIN',
  VEHICLE = 'VEHICLE',
  INFORMATION = 'INFORMATION'
};

export enum VALIDATORS {
  dateType = 'dateType',
  isDisabled = 'isDisabled',
  isRequired = 'isRequired',
  minLength = 'minLength',
  maxLength = 'maxLength',
  regex = 'regex',
  validationUrl = 'validationUrl',
};

export enum ERROR_TYPE {
  required = 'required',
  maxlength = 'maxlength',
  minlength = 'minlength',
  pattern = 'pattern',
};

export const DOCTYPE_TITLE_MAP = {
  [DOCUMENT_TYPE.INFORMATION]: 'Information',
};

export enum COMPONENT_TYPE {
  INPUT = 'INPUT',
  TEXT = 'TEXT',
  DROPDOWN = 'DROPDOWN',
  STATUS = 'STATUS',
  DYNAMIC_DROPDOWN = 'DYNAMIC_DROPDOWN',
  CHECKBOX = 'CHECKBOX',
  DATE = 'DATE',
};

export const DOCUMENT_STATUS = 'documentStatus';

export const HEADER_KEYS = {
  V2: 'v2',
  ['sg-user-city']: 'sg-user-city',
  ['auth-token-update']: 'auth-token-update',
  ['sg-auth-token']: 'sg-auth-token',
};

export const UPLOAD_ENTITY_MAPPER = {
  'captain': 'CAPTAIN',
  'vehicle': 'VEHICLE',
  'supplier': 'LIMO',
  'franchise': 'FRANCHISE',
};

export enum FORM_COMPONENT_DATA_TYPES {
  'Integer' = 'Integer',
  'String' = 'String',
  'Boolean' = 'Boolean',
};
