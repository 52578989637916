import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';

import { City } from './city';

@Injectable()
export class CitiesService {
  currentCity: string;
  previousCity: string;
  city: City;
  private readonly subject = new Subject<City>();

  constructor(private readonly httpClient: HttpClient) {}

  changeCity(city: string, offset?: number, onlineLocations = 0) {
    this.city = { city, offset, online_locations_count: onlineLocations };
    localStorage.setItem('sg_city_id', city);
    this.subject.next(this.city);
  }

  getCity(): Observable<City> {
    return this.subject.asObservable();
  }

  getCityInfo(): City {
    return this.city;
  }

  getCoreCityId() {
    const selectCitId = localStorage.getItem('sg_city_id')
    return this.httpClient.get(`cities/${selectCitId}`);
  }
}
