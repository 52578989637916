import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Select2OptionData } from 'ng-select2';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { City } from '../common/cities/city';
import { InterceptorLoggedHttpParams, InterceptorRequestConfig } from './error-logging/interceptor-http-params';
import { SOURCE_CITIES_SERVICE } from './error-logging/const/error-logging-sources';
import { HEADER_KEYS, V2_HEADER } from '../overview-v2/document-transcription-v2/shared/constants';

@Injectable()
export class GetCitiesService {
  private _cities: Array<Select2OptionData>;
  private userCities: City[] = [];
  private allCities: City[] = [];
  headers: {[key: string]: any} = {};

  constructor(
    private readonly httpClient: HttpClient
  ) {
    this._cities = [];
    this.headers = {
      [V2_HEADER]: "1",
      [HEADER_KEYS["auth-token-update"]]: "1",
      [HEADER_KEYS["sg-auth-token"]]: "1",
      ["x-city-id"]: localStorage.getItem("sg_city_id") || "",
    };
  }

  citiesList(cities?: Array<Select2OptionData>) {
    this._cities = cities ? cities : this._cities;
    return this._cities;
  }

  getAllCities(): Observable<City[]> {
    if (this.allCities.length) return of(this.allCities);
    return this.getCities(true)
      .pipe(tap(cities => this.allCities = cities));
  }

  getCities(showAll = false, isRevampVersion = false): Observable<Array<City>> {
    const showAllParam = isRevampVersion && showAll ? {showAll: '1'} :  isRevampVersion && !showAll ? {showAll: '0'} : showAll ? {showall: '1'} : {};
    const interceptorConf: InterceptorRequestConfig = {errorSource: SOURCE_CITIES_SERVICE, customAttributes: {} };
    const params = new InterceptorLoggedHttpParams(interceptorConf, showAllParam );
    const headers = {
      ...(isRevampVersion && {
        ...this.headers
      })
    };
    const url = isRevampVersion ? `/cities/admin` : `user/cities`
    return this.httpClient.get(url, { params , headers}).pipe(
      map((res: any) => res['data'].map(city => ({
        id: city.id,
        text: city.name,
        offset: city.timezone_time_offset_mins,
        country_id: city.country_id,
        online_locations_count: city.config.online_locations_count,
        country: city.country
      }))),
      tap((userCities: City[]) => this.userCities = userCities)
    );
  }

  getUserCities() {
    return this.userCities;
  }

}
