import { Injectable } from '@angular/core';
declare var Messenger: any;

@Injectable()
export class ToastrService {


  constructor() {
    this.setToastrTheme();
  }

  setToastrTheme() {
    Messenger.options = {
      extraClasses: 'messenger messenger-fixed messenger-on-top messenger-on-right messenger-theme-air',
      theme: 'air'
    };
  }

  openToastr(message, type, delay = 2) {
    Messenger().post({
      message,
      type,
      showCloseButton: true,
      hideAfter: delay
    });
  }
}
