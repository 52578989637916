import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'switch-button',
  templateUrl: './switch-button.component.html',
  styleUrls: ['./switch-button.component.scss']
})
export class SwitchButtonComponent implements OnInit {

  @Input() isToggledOn: boolean;

  constructor() { }

  ngOnInit() {
  }

}
