import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LocationType, RoleGroup } from '../common/global-interfaces';
import { HEADER_KEYS, V2_HEADER } from '../overview-v2/document-transcription-v2/shared/constants';


@Injectable()
export class LocationsService {
  headers: {[key: string]: any} = {};

  constructor(
    private readonly httpClient: HttpClient
  ) {
    this.headers = {
      [V2_HEADER]: '1',
      [HEADER_KEYS['auth-token-update']]: '1',
      [HEADER_KEYS['sg-auth-token']]: '1',
      ['x-city-id']: localStorage.getItem('sg_city_id') || '',
    };
  }

  getFranchiseLocations(type: LocationType): Observable<any> {
    return this.getLocations(type, '/location/franchise');
  }

  getLocations(type: LocationType, url = '/trainings/locations', avoidV2Headers = false): Observable<any> {
    const args = new HttpParams().set('type', type);
    const headers = avoidV2Headers ? {} : this.headers;
    return this.httpClient.get(url, { params: args, headers }).pipe(
      map((response: any) => {
        const dataSet = response.data || response;
        return dataSet?.map(location => ({ id: location.id, text: location.title }))
      }));
  }

  getLocationsForUserCreation(type: LocationType, roleGroup: RoleGroup, isRevampVersion = false): Observable<any> {
    const args = new HttpParams()
      .set('type', type)
      .set('role_group_to_create', roleGroup);
    const url = isRevampVersion ? '/admin/locations-for-user-creation' : 'locations-for-user-creation';
    const headers = {
      ...(isRevampVersion && {
        ...this.headers
      })
    };
    return this.httpClient.get(url, { params: args, headers }).pipe(
      map(res => res?.['data']),
      map((data: any) => data?.map(location => ({ id: location.id, text: location.title }))));
  }

  getFranchiseLocationById(locationId) {
    return this.httpClient.get(`supply/gate/locations?filters=id==${locationId}&include=franchise`)
      .pipe(map(res =>  res?.['data']?.[0]));
  }
}
