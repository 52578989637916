<div bsModal #modalWindow="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="trainer_modal" aria-hidden="true">
  <div class="modal-dialog {{slot.id ? 'edit-slot-modal' : ''}}">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title text-xs-center fw-bold mt" id="myModalLabel18">{{!slot.id ? 'Create New' : 'Update'}} Training Slot</h4>
        <button (click)="modalWindow.hide()" aria-label="Close" class="close" type="button">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="trainingSlot" (ngSubmit)="onSubmit(trainingSlot.value)" novalidate>
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-8 pad-r-30">
                <div class="row">
                  <div class="form-group col-md-12">
                    <div class="row">
                      <div class="col-sm-6">
                        <label class="label-header fw-semi-bold">Date</label>
                        <datetime formControlName="startTime" (ngModelChange)="onChangeStartTime($event)" [datepicker]="startTimeOptions" [timepicker]="false"></datetime>
                        <span class="error" *ngIf="trainingSlot?.controls['startTime'].hasError('startTime') && !trainingSlot?.controls['startTime'].dirty">This field is required</span>
                      </div>
                      <div class="col-sm-6">
                        <label class="label-header fw-semi-bold">Time</label>
                        <datetime formControlName="startSlotTime" [datepicker]="false" [timepicker]="{showMeridian: true, defaultTime: '12:00 AM'}"></datetime>
                        <span class="error" *ngIf="trainingSlot?.controls['startSlotTime'].hasError('startSlotTime') && !trainingSlot?.controls['startSlotTime'].dirty">This field is required</span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <div><label for="language-select" class="label-header fw-semi-bold">Language</label></div>
                    <ng-select2 id="language-select" [value]="languageId" (valueChanged)="onChangeForm('languageId', $event)" [data]="languages"
                      [options]="{theme: 'bootstrap4'}"></ng-select2>
                    <span class="error" *ngIf="trainingSlot?.controls['languageId'].hasError('required') && trainingSlot?.controls['languageId'].dirty">This field is required</span>
                  </div>
                  <div class="form-group col-md-6">
                    <div><label for="language-select" class="label-header fw-semi-bold">Capacity</label></div>
                    <input type="number" placeholder="Capacity" class="form-control" formControlName="capacity" />
                    <span class="error" *ngIf="trainingSlot?.controls['capacity'].hasError('required') && trainingSlot?.controls['capacity'].dirty">This field is required</span>
                    <span class="error" *ngIf="trainingSlot?.controls['capacity'].hasError('pattern') && trainingSlot?.controls['capacity'].dirty">Capacity must be between 1 and 1000</span>
                  </div>
                  <div class="form-group col-md-6">
                    <div><label for="location-select" class="label-header fw-semi-bold">Location</label></div>
                    <ng-select2 id="location-select" (valueChanged)="onChangeForm('locationId', $event)" [data]="locations" [value]="locationId"
                      [options]="{theme: 'bootstrap4', placeholder: 'Select'}"></ng-select2>
                    <span class="error" *ngIf="trainingSlot?.controls['locationId'].hasError('required') && trainingSlot?.controls['locationId'].dirty">This field is required</span>
                  </div>
                  <div class="form-group col-md-6">
                    <div><label for="trainer-select" class="label-header fw-semi-bold">Trainer</label></div>
                    <ng-select2 id="trainer-select" [data]="trainers" [options]="{theme: 'bootstrap4', placeholder: 'Select'}" [value]="trainerId"
                      (valueChanged)="onChangeForm('trainerId', $event)"></ng-select2>
                    <span class="error" *ngIf="trainingSlot?.controls['trainerId'].hasError('required') && trainingSlot?.controls['trainerId'].dirty">This field is required</span>
                  </div>

                  <div class="form-group col-md-6">
                    <div><label for="language-select" class="label-header fw-semi-bold">Category</label></div>
                    <ng-select2 id="default-select" (valueChanged)="onChangeForm('formOptionId', $event)" [data]="categories" [value]="category_id"
                      [options]="{theme: 'bootstrap4', placeholder: 'Select'}"></ng-select2>
                    <span class="error" *ngIf="trainingSlot?.controls['trainerId'].hasError('required') && trainingSlot?.controls['trainerId'].dirty">This field is required</span>
                  </div>
                  <div class="form-group col-md-6" *ngIf="!slot.id">
                    <div><label for="language-select" class="label-header fw-semi-bold">Type</label></div>
                    <ng-select2 id="default-select" [value]="trainingSlot?.controls['type'].value" (valueChanged)="onChangeMode($event)" [data]="modes" [options]="{theme: 'bootstrap4', placeholder: 'Select'}"></ng-select2>
                    <span class="error" *ngIf="trainingSlot?.controls['trainerId'].hasError('required') && trainingSlot?.controls['trainerId'].dirty">This field is required</span>
                  </div>
                </div>
              </div>
              <div class="col-sm-4 border-left pad-l-30">
                <div *ngIf="!slot?.id" class="form-group {{trainingSlot?.get('type').value === 'repeat' ? '' : 'disable-wrapper'}}">
                  <div><label for="language-select" class="label-header fw-semi-bold">Repeat</label></div>
                  <ng-select2 (valueChanged)="onChangeForm('repeatType', $event)" [data]="types" [options]="{theme: 'bootstrap4', placeholder: 'Select'}"></ng-select2>
                </div>
                <div *ngIf="!slot?.id" class="form-group {{trainingSlot?.get('type').value === 'repeat' ? '' : 'disable-wrapper'}}">
                  <label class="label-header fw-semi-bold">End Date</label>
                  <datetime [datepicker]="endDateOptions" [timepicker]="false" formControlName="endDate"></datetime>
                </div>
                <div *ngIf="!slot?.id" class="form-group {{trainingSlot?.get('type').value === 'repeat' && trainingSlot?.get('repeatType').value === 'weekly' ? '' : 'disable-wrapper'}}">
                  <div><label for="language-select" class="label-header fw-semi-bold">Repeats Every</label></div>
                  <input type="number" placeholder="Repeats Every" class="form-control" formControlName="frequency" />
                  <span class="error" *ngIf="trainingSlot?.controls['frequency'].hasError('pattern') && trainingSlot?.controls['frequency'].dirty">Repeats Every must be between 1 and 25</span>
                </div>
                <div *ngIf="!slot?.id" class="form-group {{trainingSlot?.get('type').value === 'repeat' && trainingSlot?.get('repeatType').value === 'weekly' ? '' : 'disable-wrapper'}}">
                  <div><label class="label-header fw-semi-bold">Repeats on</label></div>
                  <div *ngFor="let day of days" class="abc-checkbox">
                    <input #cv [id]="day.id" type="checkbox" (click)="onDayChange(day.id, cv.checked)" /><label [for]="day.id">{{day.text}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-gray" (click)="modalWindow.hide()">Close</button>
            <button type="submit" class="btn btn-success" [disabled]="!trainingSlot?.valid">{{!slot.id ? 'Create New' : 'Update'}} Slot</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>