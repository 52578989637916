import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';

@Injectable()
export class SpinnerService {
  spinner: Subject<any>;

  constructor() {
    this.spinner = new Subject<any>();
  }

  change(value) {
    this.spinner.next(value);
  }

  showSpinner(): Observable<any> {
    return this.spinner;
  }
}
