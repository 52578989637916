import { AfterViewInit, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { ToastrMessages } from 'src/app/common/constants';

import { Company } from '../../../limo-company/limo-company';
import { LimoCompanyService } from '../../../limo-company/limo-company.service';
import { ToastrService } from '../../toastr/toastr.service';
import { DynamicModalService } from '../dynamic-modal/dynamic-modal.service';

@Component({
  selector: 'add-limo-company',
  templateUrl: './add-limo-company.template.html',
  providers: [LimoCompanyService]
})

export class AddLimoCompanyModalComponent implements OnInit, AfterViewInit {
  @ViewChild('modalWindow') limoModal;
  new_limo: FormGroup;
  isFormSubmitted = false;
  company: Company;
  language: Array<any>;
  onClose: Subject<any>;


  constructor(
    private formBuilder: FormBuilder,
    private limoCompanyService: LimoCompanyService,
    private dynamicModalService: DynamicModalService,
    private injector: Injector,
    private toastrService: ToastrService
  ) {

  }
  ngOnInit() {
    this.new_limo = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(/^[^<>]+$/)]],
      solution_system_id: ['', [Validators.required, Validators.pattern(/^\d+$/)]]
    });
    this.onClose = this.injector.get('onClose');
  }

  ngAfterViewInit() {
    this.limoModal.show();
    this.limoModal.onHidden.subscribe(() => this.dynamicModalService.modalOnClose.next(null));
  }

  onSubmit(form) {
     this.create(form);
  }

  create(form: any) {
    this.limoCompanyService.addLimoCompany(form)
      .subscribe(data => {
        this.toastrService.openToastr(ToastrMessages.addedLimoSuccess, ToastrMessages.success);
        this.limoModal.hide();
        this.new_limo.reset();
        this.closeModal(data);
      },
      err => {
        this.new_limo.reset();
      });
  }

  closeModal(data: any) {
    this.onClose.next(data);
    this.onClose.complete();
  }
}
