import {
  ACTION,
  CarCheckStatusDetails,
  DownloadStatusDetails,
  TrainingStatusDetails,
  TranscriptionStatusDetails
} from '../models';

export enum COLOR {
  GRAY = 'gray',
  GREEN = 'green',
  RED = 'red',
  YELLOW = 'yellow'
}

export enum STATUS_KEY {
  captainType = 'captainType',
  cumulative = 'cumulative',
  transcription = 'transcription',
  training = 'training',
  carCheck = 'carCheck',
  externalVerification = 'externalVerification',
  download = 'download',
};

export const STATUS_MAP = {
  'captainType': {
    getDescription: () => ''
  },
  'cumulative': {
    icon: null,
    colors: {
      'null': COLOR.GRAY,
      1: COLOR.GRAY,
      2: COLOR.GREEN,
      3: COLOR.RED,
      4: COLOR.YELLOW,
      5: COLOR.YELLOW,
      6: COLOR.YELLOW
    },
    getDescription: (details: any) => ''
  },
  'transcription': {
    icon: 'fa-file',
    colors: {
      'null': COLOR.GRAY,
      1: COLOR.YELLOW,
      2: COLOR.YELLOW,
      3: COLOR.RED,
      4: COLOR.GREEN,
      5: COLOR.YELLOW,
      6: COLOR.RED
    },
    getDescription: (details: TranscriptionStatusDetails) => {
      if (!details || !details.transcriber) return '';
      let name = '';
      if (details.transcriber.firstName) {
        name += `${details.transcriber.firstName} `;
      }
      if (details.transcriber.lastName) name += details.transcriber.lastName;
      return name;
    }
  },
  'training': {
    icon: 'fa-graduation-cap',
    colors: {
      'null': COLOR.GRAY,
      1: COLOR.YELLOW,
      2: COLOR.GREEN,
      3: COLOR.RED,
      4: COLOR.YELLOW
    },
    getDescription: (details: TrainingStatusDetails) => {
      if (!details || !details.slot || details.slot.earnedScore === null) return '';
      return `Score: ${details.slot.earnedScore}/${details.slot.totalScore}`;
    }
  },
  'carCheck': {
    icon: 'fa-car',
    colors: {
      'null': COLOR.GRAY,
      0: COLOR.GRAY,
      1: COLOR.YELLOW,
      2: COLOR.GREEN,
      3: COLOR.RED
    },
    getDescription: (details: CarCheckStatusDetails) => {
      return '';
    }
  },
  'externalVerification': {
    icon: 'fa-shield',
    colors: {
      'null': COLOR.GRAY,
      1: COLOR.YELLOW,
      2: COLOR.GREEN,
      3: COLOR.RED
    },
    getDescription: (details: any) => ''
  },
  'download': {
    icon: 'fa-share-square-o',
    colors: {
      'null': COLOR.GRAY,
      0: COLOR.GRAY,
      1: COLOR.GREEN,
      2: COLOR.YELLOW,
      3: COLOR.RED
    },
    getDescription: (details: DownloadStatusDetails) => {
      if (!details) return '';
      return details.comment || '';
    }
  },
};

export const ACTION_PERMISSIONS: { [key in ACTION]: string[] } = {
  viewProfile: [ 'admin-view-lead-profile' ],
  checkIn: [ 'admin-overview-car-check-and-training' ],
  bookTrainingSlot: [ 'admin-update-training' ],
  forceTrainingResult: [ 'admin-overview-car-check-and-training' ],
  sendTrainingReminder: [ 'admin-overview-car-check-and-training' ],
  carCheck: [ 'admin-overview-car-check-and-training' ],
  viewExternalVerificationDetails: [ 'admin-overview-car-check-and-training' ],
  retryExternalVerification: [ 'admin-overview-car-check-and-training' ]

};

export const ACTION_STATUS_MAP = {
  transcription: 'viewProfile',
  carCheck: 'carCheck',
};
