<!--Modal Template START-->
<div bsModal #modalWindow="bs-modal" (onHide)="hideModal()" [config]="{backdrop: false}" class="modal fade prompt-modal"
  tabindex="-1" role="dialog" aria-labelledby="import_limo_company_modal" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header text-capitalize">
        <div class="text-xs-center">
          <h4 class="modal-title text-xs-center fw-bold mt" id="myModalLabel18">{{title}}</h4>
        </div>
      </div>
      <div class="modal-body">
        <p>{{message}}</p>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-success btn-sm text-uppercase" (click)="closeModal(true)">
          {{confirmButton}}
        </button>
      </div>
    </div>
  </div>
</div>
<!--Modal Template END-->
