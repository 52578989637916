<section class="widget" widget>
  <div class="widget-body">
    <div class="table-responsive">
      <table class="table table-striped table-hover" (mfSortByChange)="onChangeSortBy($event)" (mfSortOrderChange)="onChangeSortOrder($event)"
        [mfData]="captains" #mf="mfDataTable" [mfRowsOnPage]="10">
        <caption></caption>
        <thead>
          <tr>
            <th scope="col">
              <mfDefaultSorter by="name">Name</mfDefaultSorter>
            </th>
            <th scope="col">
              <mfDefaultSorter by="email">E-mail</mfDefaultSorter>
            </th>
            <th scope="col">
              <mfDefaultSorter by="cell_phone">Cell Phone</mfDefaultSorter>
            </th>
            <th scope="col">
              <mfDefaultSorter by="created_at">Created At</mfDefaultSorter>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of mf.data">
            <td><span class="fw-semi-bold">{{item.first_name}} {{item.last_name}}</span></td>
            <td><span class="fw-semi-bold">{{item.email}}</span></td>
            <td><span class="fw-semi-bold">{{item.cell_phone}}</span></td>
            <td><span class="fw-semi-bold">{{item.created_at | date: 'EEEE MMM dd yyyy hh:mm a'}}</span></td>
          </tr>
          <tr *ngIf="mf.data.length === 0">
            <td class="no-matches" colspan="100">
              No matches
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="paginationWrapper">
      <pagination [totalItems]="paginationObject?.total" [(ngModel)]="currentPage" (ngModelChange)='onModelChange($event)' [maxSize]="maxSize" (pageChanged)="pageChanged($event.page)">
      </pagination>
    </div>
  </div>
</section>