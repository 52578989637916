import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Select2OptionData } from 'ng-select2';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HEADER_KEYS } from '../overview-v2/document-transcription-v2/shared/constants';

@Injectable()
export class LanguagesService {
  constructor(private readonly httpClient: HttpClient) {
  }

  getLanguages(): Observable<any> {
    return this.httpClient.get('languages').pipe(
      map(res => res['data']),
      map((data: any) => data.map(language => ({ id: language.id, text: language.name }))));
  }

  getCityLanguages(cityId: number): Observable<Array<Select2OptionData>> {
    return this.httpClient.get('languages/city/' + cityId).pipe(
      map((res) => res['data'].map(city => ({
          id: city.id,
          text: city.name
        }))));
  }

  // CSGV-1456 - used to get active languages
  getActiveLanguages(): Observable<Array<Select2OptionData>> {
    const headers = new HttpHeaders({
      [HEADER_KEYS.V2]: '1',
      [HEADER_KEYS['sg-user-city']]: localStorage.getItem('sg_city_id') || '',
      [HEADER_KEYS['auth-token-update']]: '1',
      [HEADER_KEYS['sg-auth-token']]: '1',
    })
    return this.httpClient.get('/cities/active-languages', { headers }).pipe(
    map((res) => res['data'].map(city => ({
      id: city.id,
      text: city.name
    }))));
  }
}
