import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Select2OptionData } from 'ng-select2';
import { empty as observableEmpty, Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { DownloadFileResponse } from 'src/app/common/global-interfaces';
import { ISlotPageOptions, TrainingQueryParams } from 'src/app/training-slots/slot';
import { HEADER_KEYS, V2_HEADER } from '../overview-v2/document-transcription-v2/shared/constants';

@Injectable()
export class TrainingSlotsService {
  headers: {[key: string]: any} = {};
  slotPageOptions: ISlotPageOptions;
  pageNumber: number = 0;

  constructor(
    private httpClient: HttpClient,
  ) {
    this.headers = {
      [V2_HEADER]: '1',
      [HEADER_KEYS['auth-token-update']]: '1',
      [HEADER_KEYS['sg-auth-token']]: '1',
      ['x-city-id']: localStorage.getItem('sg_city_id') || '',
    };

    this.slotPageOptions = {
      offset: 0,
      limit: 10,
    };
  }

  getSlots(params: TrainingQueryParams, initPageFilters = false): Observable<any> {
    if(initPageFilters) {
      this.pageNumber = 0;
      this.slotPageOptions = {
        offset: 0,
        limit: 10,
      };
    };

    const args: any = {
      ...this.slotPageOptions,
    };
    args.from = String(params.from);
    args.to = String(params.to);
    if (Number(params.location_id)) {
      args.locationId = String(params.location_id);
    }
    if (Number(params.language_id)) {
      args.languageId = String(params.language_id);
      args.langId = String(params.language_id);
    }
    if (Number(params.trainer_id)) {
      args.trainerId = String(params.trainer_id);
    }
    if (Number(params.captain_type_id)) {
      args.captain_type_id = String(params.captain_type_id);
    }

    this.setSlotPageOptions();
    return this.httpClient.get(`/trainings/slots?${new HttpParams({ fromObject: args }).toString().replace(/\+/gi, '%2B')}`, { headers: this.headers })
      .pipe(catchError(() => observableEmpty()));
  }

  getSlot(id: number): Observable<any> {
    return this.httpClient.get(`training/${id}`);
  }

  getAvailableTrainings(params: TrainingQueryParams, isRevampVersion = false) {
    let args = new HttpParams()
      .set('language_id', String(params.language_id))
      .set('location_id', String(params.location_id));

    if (params.captain_type_id) {
      args = args.set('captain_type_id', String(params.captain_type_id));
    }
    const headers = {
      ...(isRevampVersion && {
        ...this.headers
      })
    };
    const url = isRevampVersion ? `/trainings/${params.location_id}/booking-slots?languageId=${params.language_id}` : `trainings/available?${args.toString()}`;
    return this.httpClient.get(url,{headers});
  }


  getCityFormOptions(): Observable<Select2OptionData[]> {
    return this.httpClient.get('/captain-types', { headers: this.headers }).pipe(
      map((options: any) => options?.data?.map((option) => ({ id: String(option.id), text: option.title_key }))));
  }

  moreSlots(params: any) {
    const args: any = {
      ...this.slotPageOptions,
    };
    // args.date = params.date;
    args.from = String(params.from);
    args.to = String(params.to);
    if (Number(params.location_id)) {
      args.locationId = String(params.location_id);
    }
    if (Number(params.language_id)) {
      args.languageId = String(params.language_id);
    }
    if (Number(params.trainer_id)) {
      args.trainerId = String(params.trainer_id);
    }

    this.setSlotPageOptions();
    return this.httpClient.get(`/trainings/slots?${new HttpParams({ fromObject: args }).toString().replace(/\+/gi, '%2B')}`, { headers: this.headers })
      .pipe(catchError(() => observableThrowError({})));
  }

  bookSlot(data: any) {
    return this.httpClient.post(`training/${data.training_id}/book`, data);
  }

  createSlot(training: any): Observable<any> {
    return this.httpClient.post(`/trainings/`, training, { headers: this.headers }).pipe(
      catchError(err => observableEmpty()));
  }

  downloadCaptains(id: number): Observable<any> {
    return this.httpClient.get(`download/captains/${id}`);
  }

  downloadApprovedCaptains(id: number): Observable<any> {
    return this.httpClient.get(`download-complete-profiles?training_id=${id}`);
  }

  downloadURI(file: DownloadFileResponse) {
    const link = document.createElement('a');
    link.download = file.file_name;
    link.href = file.url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  startTraining(id: number): Observable<any> {
    return this.httpClient.patch(`training/${id}/start`, { });
  }

  stopTraining(id: number): Observable<any> {
    return this.httpClient.patch(`training/${id}/stop`, { });
  }

  updateSlot(training: any, id: number): Observable<any> {
    return this.httpClient.put(`/trainings/${id}`, training, { headers: this.headers });
  }

  deleteSlot(id: number): Observable<any> {
    return this.httpClient.delete(`/trainings/${id}`, { headers: this.headers });
  }

  moveSlotAndDelete(id: number, to_training_id: number) {
    const args = new HttpParams().set('to_training_id', String(to_training_id));
    return this.httpClient.delete(`training/${id}`, { params: args });
  }

  setSlotPageOptions() {
    this.pageNumber = this.pageNumber + 1;
    this.slotPageOptions = {
      ...this.slotPageOptions,
      offset: this.pageNumber * this.slotPageOptions.limit,
    };
  }
}
