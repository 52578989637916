import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';
import { API_V2 } from '../overview-v2/document-transcription-v2/shared/constants';

@Injectable()
export class CacheService {
  private readonly URLS_TO_CACHE;
  private readonly cacheMap = new Map();

  constructor() {
    this.URLS_TO_CACHE = [
      '/api/user/cities',
      '/api/user/cities?showall=1'
    ];
  }

  get(req: HttpRequest<any>): HttpResponse<any> | null {
    const cache = this.cacheMap.get(req.urlWithParams);
    if (!cache) {
      return null;
    }
    return cache.response;
  }

  set(req: HttpRequest<any>, res: HttpResponse<any>): void {
    const entry = { url: req.urlWithParams, response: res, entryTime: Date.now() };
    this.cacheMap.set(req.urlWithParams, entry);
  }

  deleteCache() {
    this.cacheMap.forEach(entry => {
      this.cacheMap.delete(entry.url);
    });
  }

  isRequestCachable(req: HttpRequest<any>) {
    return req.method === 'GET' && this.shouldURLBeCached(req.urlWithParams);
  }

  private shouldURLBeCached(url) {
    return this.URLS_TO_CACHE.some(item => url.indexOf(item) > -1);
  }
}
