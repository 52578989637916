import { Injectable } from '@angular/core';
import { AuthService } from '..';
import { ApiErrorResponse } from '../interceptors/interfaces';
import { HttpRequest } from '@angular/common/http';
import { InterceptorLoggedHttpParams, InterceptorRequestConfig } from './interceptor-http-params';
import { SOURCE_MISC } from './const/error-logging-sources';

// declare let newrelic: any;
declare let elasticApm: any;

export interface NewRelicSharedAttributes {
  city?: string;
  cityId?: string;
  country?: string;
  countryId?: string;
  user?: string;
  userId?: number;
  stackTrace?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ErrorLoggingService {

  constructor(
    private readonly authService: AuthService
  ) {}

  /**
   * Takes a http error response and logs the error to monitoring service (e.g. NewRelic)
   * @param err Http Error Response
   * @param customAttributes
   */
  logBasicRequestError(err: ApiErrorResponse, customAttributes = {}) {
    if (!!elasticApm) {
      const commonAttributes: NewRelicSharedAttributes = this.getCommonNewRelicAttributes();

      // Add custom attributes related to API responses
      const requestAttributes = {
        httpResponseStatusCode: err.status || 'undefined',
        httpResponseStatusText: err.statusText || 'undefined',
        httpResponseMessage: err.message || 'undefined',
        httpRequestUrl: err.url || 'undefined'
      };

      this.sendErrorReport(err, { ...commonAttributes, ...requestAttributes, ...customAttributes });
    }
  }

  /**
   * Log the provided error response. More detailed logging if request fulfills specific criteria
   *  * request.params must be instanced from an extended http params class for detailed logging
   * @param request
   * @param errorResponse
   */
  logInterceptorError(request: HttpRequest<any>, errorResponse: ApiErrorResponse) {
    if (!!elasticApm && this.isDetailedLogPossible(request)) {
      try {
        // Collect attributes that should be used for all logging cases if they are available
        const commonAttributes: NewRelicSharedAttributes = this.getCommonNewRelicAttributes();

        // Extract all relevant attributes from the error response
        let errorResponseAttributes = {};
        if (errorResponse) {
          errorResponseAttributes = {
            httpResponseStatusCode: errorResponse.status || 'undefined',
            httpResponseStatusText: errorResponse.statusText || 'undefined',
            httpResponseMessage: errorResponse.message || 'undefined',
            httpRequestUrl: errorResponse.url || 'undefined',
            httpRequestEndpoint: request.urlWithParams || 'undefined'
          };
        }

        // Extact all parameters as key value pair that were used for the request
        const paramsFromRequest = {};
        const parameterKeys: string[] = (request.params as InterceptorLoggedHttpParams).keys();
        parameterKeys.forEach(param => {
          paramsFromRequest[param] = request.params.get(param);
        });

        // Extract all custom attributes that were used for the request
        const interceptorRequestConfig: InterceptorRequestConfig  = (request.params as InterceptorLoggedHttpParams).interceptorConfig;
        const customAttributes = { errorSource: interceptorRequestConfig.errorSource };
        Object.keys(interceptorRequestConfig.customAttributes)
          .forEach((key) => {
            customAttributes[key] = interceptorRequestConfig.customAttributes[key];
          });

        // In case of duplicate attributes, priority will be custom > paramsFromRequest > request > common
        const attributes = {...commonAttributes, ...errorResponseAttributes, ...paramsFromRequest, ...customAttributes};

        this.sendErrorReport(errorResponse, attributes);
      } catch (error) {
        console.error(error);
      }
    } else {
      this.logBasicRequestError(errorResponse, {errorSource: SOURCE_MISC});
    }
  }

  /**
   * Log a custom error to different services (e.g. NewRelic)
   * @param err
   * @param customAttributes
   */
  logCustomError(err: Error, customAttributes = {}): void {
    if (!!elasticApm) {
      const commonAttributes: NewRelicSharedAttributes = this.getCommonNewRelicAttributes();
      const attributes = { ...commonAttributes, ...customAttributes};
      this.sendErrorReport(err, attributes);
    }
  }

  /**
   * Gather all attributes that should be shared across all reports
   */
  private getCommonNewRelicAttributes(): NewRelicSharedAttributes {
    const userInfo = this.authService.getUserInfo();
    const commonAttributes = {};

    if (userInfo) {
      commonAttributes['userId'] = userInfo.id;
    }

    if (userInfo && userInfo.city) {
      commonAttributes['city'] = userInfo.city.name;
      commonAttributes['cityId'] = userInfo.city.id;
    }

    if (userInfo && userInfo.city && userInfo.city.country) {
      commonAttributes['country'] = userInfo.city.country.name;
      commonAttributes['countryId'] = userInfo.city.country.id;
    }

    return commonAttributes;
  }

  /**
   * Creates a error report object and sends it to logging service
   * @param errorObject
   * @param attributes
   */
  private sendErrorReport(errorObject: Error, attributes: Object) {
    if (!!elasticApm) {
      elasticApm.captureError(this.createReportObject(errorObject), attributes);
    }
  }

  /**
   * Takes an error object and will try to create a new copy of it.
   *  If the provided object is an Error Object (created by new Error for example), the same object will be returned.
   * Following attributes will be added if they are not found:
   *    stack:  String of the current stack trace
   * New object is created so new attributes can be added specific for logging service without modifying the original object
   * @param errorResponse
   */
  private createReportObject(errorResponse: Error | ApiErrorResponse) {
    const reportObject = { ...errorResponse };

    if (errorResponse instanceof Error) {
      return errorResponse;
    }

    if (
      reportObject['stack'] === undefined ||
      reportObject['stack'] === null ||
      reportObject['stack'] === ''
    ) {
      const errorName = errorResponse.name || errorResponse.message || 'Default error';
      reportObject['stack'] = new Error(errorName).stack;
    }

    return reportObject;
  }

  /**
   * Returns true if te requests params attribute is an instance of the extended class InterceptorLoggedHttpParams
   * @param request
   */
  private isDetailedLogPossible(request: HttpRequest<any>): boolean {
    return request.params instanceof InterceptorLoggedHttpParams;
  }
}
