import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable()
export class LoginAuthGuard implements CanActivate {

  constructor(
    private readonly authService: AuthService,
    private readonly router: Router
  ) {
  }

  canActivate(routeSnapshot: ActivatedRouteSnapshot) {
    if (!this.authService.getToken()) {
      return true;
    }
    if (routeSnapshot.queryParams['redirectRef']) {
      const url: string = routeSnapshot.queryParams['redirectRef'];
      const params = { id: routeSnapshot.queryParams['id'] };
      this.router.navigate([url], { queryParams: params });
      return false;
    } else {
      this.authService.redirectToDashboard();
      return false;
    }

  }
}
