import { Component } from '@angular/core';

@Component({
  selector: 'spinner',
  template: `
  <div class='custom-loader'>
    <span></span>
  </div>
  `
})

export class SpinnerComponent {

  constructor() {

  }
}
