import { Menu } from './menu';

export const sideBarMenu: Menu[] = [
  {
    id: 'overview',
    name: 'Overview',
    icon: 'fa fa-binoculars',
    subMenu: [
      {
        id: 'overview-captain',
        right: 'admin-overview',
        name: 'Captains',
        link: '/overview'
      },
      {
        id: 'overview-vehicle',
        right: 'admin-vehicle-overview',
        name: 'Vehicles',
        link: '/overview/vehicle'
      },
      {
        id: 'overview-supplier',
        right: 'admin-supplier-overview',
        name: 'Supplier',
        link: '/overview/supplier'
      }
    ]
  },
  {
    id: 'training-slots',
    right: 'admin-view-training-slots',
    name: 'Training Slots',
    link: '/training-slots',
    icon: 'fa fa-calendar'
  },
  {
    id: 'reporting',
    name: 'Reporting',
    icon: 'fa fa-list-alt',
    subMenu: [
      {
        id: 'summary',
        right: 'admin-reporting',
        name: 'Summary',
        link: '/reporting'
      },
      {
        id: 'download-history',
        right: 'admin-download-list-for-solution',
        name: 'Download History',
        link: '/download-history'
      },
      {
        id: 'download-data',
        right: 'download-data',
        name: 'Captains',
        link: '/download-data/all-captains'
      },
      {
        id: 'download-data',
        right: 'download-data',
        name: 'Help Leads',
        link: '/download-data/help-leads'
      },
      {
        id: 'download-data',
        right: 'download-data',
        name: 'Optional Documents',
        link: '/download-data/optional-docs'
      },
      {
        id: 'download-data',
        right: 'download-excel-url',
        name: 'Download Documents',
        link: '/download-data/captain-docs'
      },
      {
        id: 'export-status',
        right: 'admin-view-captain-creation-status',
        name: 'Export Status',
        link: '/download-data/export-status'
      }
    ]
  },
  {
    id: 'find-a-captain',
    right: 'find-a-captain',
    name: 'Find A Captain',
    link: '/find-a-captain',
    icon: 'fa fa-circle-o-notch'
  },
  {
    id: 'user-management',
    right: 'admin-manage-users',
    name: 'User Management',
    link: '/user-management',
    icon: 'fa fa-user'
  },
  {
    id: 'replicate-city',
    right: 'replicate-city',
    name: 'Replicate City',
    link: '/replicate-city',
    icon: 'fa fa-user'
  },
  {
    id: 'support',
    name: 'Support',
    icon: 'fa fa-comments',
    subMenu: [
      {
        id: 'create-ticket',
        right: 'create-update-ticket',
        name: 'Create Ticket',
        link: '/support/ticket/create-ticket',
        icon: 'fa fa-download',
        toggle: 'ENABLE_SG_SUPPORT_TICKET_MANAGEMENT'
      },
      {
        id: 'disposition-management',
        right: 'create-update-disposition',
        name: 'Manage Dispositions',
        link: '/support/disposition',
        toggle: 'ENABLE_SG_SUPPORT_DISPOSITION_MANAGEMENT'
      }
    ]
  },
  {
    id: 'add-lead',
    name: 'Add Lead',
    link: '',
    icon: 'fa fa-plus',
    subMenu: [
      {
        id: 'add-captain',
        right: 'admin-add-captain',
        name: 'Captain',
        link: '/add-captain/verify'
      },
      {
        id: 'add-vehicle',
        right: 'admin-add-captain',
        name: 'Vehicle',
        link: '/add-lead/vehicle'
      },
      {
        id: 'add-supplier',
        right: 'admin-add-captain',
        name: 'Supplier',
        link: '/add-lead/supplier',
        icon: 'fa fa-plus'
      }
    ]
  },
  {
    id: 'cms',
    name: 'CMS',
    link: '',
    icon: 'fa fa-plus',
    subMenu: [
      {
        id: 'cms-documents-upload',
        right: 'admin-view-city-document',
        name: 'Documents Upload',
        link: '/cms/documents-upload'
      },
      {
        id: 'cms-city-status',
        // TODO: Change right for this one!
        right: 'replicate-city',
        name: 'City Status',
        link: '/cms/city-status'
      }
    ]
  }
];
