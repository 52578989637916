import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DynamicModalComponent } from './dynamic-modal.component';
import { DynamicModalService } from './dynamic-modal.service';

@NgModule({
    declarations: [
        DynamicModalComponent
    ],
    imports: [
        CommonModule
    ],
    providers: [
        DynamicModalService
    ],
    exports: [
        DynamicModalComponent
    ]
})
export class DynamicModalModule {
}
