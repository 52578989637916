<div bsModal #modalWindow="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title text-xs-center fw-bold mt" id="myModalLabel18">Move Captains</h4>
                <button (click)="closeModal()" aria-label="Close" class="close" type="button">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="header-training-move">
              <span>You have {{slot.attendees_count}} Captain(s) in this slot. Move them to another available slot.</span>
            </div>
            <form [formGroup]="form" (ngSubmit)="moveSlot()" novalidate>
                <div class="modal-body">
                    <div class="row">
                        <div class="form-group col-md-12">
                            <label for="slot-select" class="label-header fw-semi-bold">Available Slots</label>
                            <div>
                                <ng-select2 id="slot-select" [value]="to_training_id" (valueChanged)="onChangeSlot($event)" name="slot" [data]="availableTrainings" [options]="{theme: 'bootstrap4'}"
                                    [width]="'100%'"></ng-select2>
                            </div>
                            <span *ngIf="availableTrainings?.length === 0" class="small">No training slot is available in future.</span>
                            <span *ngIf="selectedTraining">You can move {{selectedTraining?.available_slots}} attendees to this training slot.</span>
                            <span class="error" *ngIf="form.controls['to_training_id'].hasError('required') && form.controls['to_training_id'].dirty">This field is required</span>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-gray" (click)="closeModal()">Close</button>
                    <button type="submit" class="btn btn-success" [disabled]="!form.valid">Move Captains</button>
                </div>
            </form>
        </div>
    </div>
</div>
