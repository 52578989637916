<!--Modal Template START-->
<div bsModal #modalWindow="bs-modal" class="modal fade" tabindex="-1" role="dialog"
     aria-labelledby="import_limo_company_modal" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header text-capitalize">
        <button (click)="modalWindow.hide()" aria-label="Close" class="close" type="button">
          <span aria-hidden="true">×</span>
        </button>
        <div class="text-xs-center">
          <h4 class="modal-title text-xs-center fw-bold mt" id="myModalLabel18">Delete Limo Company</h4>
        </div>
      </div>
      <div class="modal-body">
        <form [formGroup]="new_limo_delete" novalidate (ngSubmit)="onSubmit(new_limo_delete.value)">
          <div class="row">
            <div class="col-lg-12 col-xs-12">
              <div class="form-group">
                <label class="label-header fw-semi-bold" for="id">Are you sure you want to delete this limo company ?<br>
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-default btn-sm"
                    (click)="modalWindow.hide()">
              Cancel
            </button>
            <button type="submit"
                    class="btn btn-danger btn-sm"
                    [disabled]="!new_limo_delete.valid">
              Delete
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!--Modal Template END-->
