import { AfterViewInit, Component, Injector,  OnInit, ViewChild } from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Select2OptionData } from 'ng-select2';
import { Subject } from 'rxjs';
import { first, tap } from 'rxjs/operators';

import { DateService, LanguagesService, LocationsService, SpinnerService, ToastrService } from '../../';
import { OverviewService } from '../../../overview/overview.service';
import { TrainerManagementService } from '../../../trainer-management/trainer-management.service';
import { DynamicModalService } from '../dynamic-modal/dynamic-modal.service';

import { Trainer } from '../../../trainer-management/trainer';
import { regex } from '../../constants';
import { DatePickerOptions } from '../../global-interfaces';

@Component({
  selector: 'add-trainer',
  templateUrl: './add-trainer.template.html'
})

export class AddTrainerModalComponent implements OnInit, AfterViewInit {
  @ViewChild('modalWindow') trainerModal;
  new_trainer: FormGroup;
  selectedLanguages: Array<number> = [];
  locations: Array<Select2OptionData>;
  isFormSubmitted = false;
  setSubmitted = false;
  trainer: Trainer;
  onClose: Subject<Function>;
  location: Array<string>;
  languages: Array<Select2OptionData>;
  language: Array<number>;

  select2Options: Select2Options = {
    multiple: true,
    theme: 'bootstrap4'
  };

  datePickerOptions: DatePickerOptions = {
    autoclose: true,
    todayHighlight: true
  };

  constructor(
    private formBuilder: FormBuilder,
    private overviewService: OverviewService,
    private trainerManagementService: TrainerManagementService,
    private locationService: LocationsService,
    private toastrService: ToastrService,
    private dateService: DateService,
    private spinnerService: SpinnerService,
    private dynamicModalService: DynamicModalService,
    private injector: Injector,
    private languageService: LanguagesService
  ) {}

  ngOnInit() {
    this.trainer = this.injector.get('trainer');
    this.onClose = this.injector.get('onClose');
    this.new_trainer = this.formBuilder.group({
      first_name: [this.trainer.user_id ? this.trainer.user.first_name : '', [
        Validators.required, Validators.pattern(regex.name)]
      ],
      last_name: [this.trainer.user_id ? this.trainer.user.last_name : '', [
        Validators.required, Validators.pattern(regex.name)]
      ],
      email: [this.trainer.user_id ? this.trainer.user.email : '', [
        Validators.required, Validators.pattern(regex.email)]
      ],
      joining_date: [this.trainer.user_id ? this.dateService.getCityDate(this.trainer.joining_date) : '',
        Validators.required
      ],
      locations: [1, Validators.required]
    });

    this.languageService.getActiveLanguages().pipe(
      first())
      .subscribe(languages => {
        this.languages = languages;
        if (this.trainer.user_id) {
          this.language = this.trainer.languages.map(language => language.id);
        } else {
          this.language = [1];
        }
      });

    this.locationService
      .getLocations('captain').pipe(
      tap(() => {
        if (this.trainer.user_id) {
          this.location = this.trainer.locations.map(location => location.id);
        }
      }),
      first())
      .subscribe((locations) => this.locations = locations);
  }

  ngAfterViewInit() {
    this.trainerModal.show();
    this.trainerModal.onHidden
      .subscribe(() => this.dynamicModalService.modalOnClose.next(null));
  }

  onChangeLanguage(language: Array<string>) {
    this.language = language.map(language => Number(language));
  }

  onChangeLocation(locations: Array<string>) {
    this.new_trainer.controls['locations'].setValue(locations.join(','));
  }

  onSubmit(form: Trainer) {
    this.setSubmitted = true;
    form.languages = <any> this.language.join(',');
    form.joining_date = this.dateService.getDate(form.joining_date);
    this.trainer.user_id ? this.update(form) : this.create(form);
  }

  create(form: Trainer) {
    this.spinnerService.change(true);
    this.trainerManagementService.addTrainer(form)
      .subscribe(() => this.onSubmitted('update'), () => this.setSubmitted = false);
  }

  update(form: Trainer) {
    this.spinnerService.change(true);
    this.trainerManagementService.updateTrainer(form, this.trainer.user_id)
      .subscribe(() => this.onSubmitted('create'), () => this.setSubmitted = false);
  }

  onSubmitted(type?: string) {
    this.spinnerService.change(false);
    this.toastrService.openToastr(`Successfully ${type}d trainer information`, 'success');
    this.trainerModal.hide();
    this.new_trainer.reset();
  }
}
