import { Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: `[validateEqual][formControlName],[validateEqual]
    [formControl],[validateEqual][ngModel]`,
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => ValidateEqualDirective), multi: true }
  ]
})

export class ValidateEqualDirective implements Validator {

  @Input('validateEqual') validateEqual: string;
  @Input('reverse') reverse: string;
  constructor(
  ) { }

  private get isReverse() {
    if (!this.reverse) return false;
    return this.reverse === 'true';
  }

  validate(absCtrl: AbstractControl): { [key: string]: any } {
    const currentVal = absCtrl.value;
    const passwordControl = absCtrl.root.get(this.validateEqual);
    if (passwordControl && currentVal !== passwordControl.value && !this.isReverse) {
      return {
        validateEqual: true
      };
    }

    if (passwordControl && currentVal === passwordControl.value && this.isReverse) {
      delete passwordControl.errors['validateEqual'];
      if (!Object.keys(passwordControl.errors).length) passwordControl.setErrors(null);
    }


    if (passwordControl && currentVal !== passwordControl.value && this.isReverse) {
      passwordControl.setErrors({ validateEqual: true }, { emitEvent: true });
    }
    return null;
  }

}
