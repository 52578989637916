import { Component, ComponentFactoryResolver, Input, ReflectiveInjector, ViewChild, ViewContainerRef } from '@angular/core';

@Component({
  selector: 'dynamic-modal',
  template: `
    <div #dynamicModalContainer></div>
    `
})
export class DynamicModalComponent {
  currentModalComponent = null;
  @ViewChild('dynamicModalContainer', { read: ViewContainerRef }) dynamicModalContainer: ViewContainerRef;
  @Input() set componentData(data: { component: any, inputs: any }) {
    if (!data) return;
    let factory, component;
    if (data.inputs) {
      const inputProviders = Object.keys(data.inputs).map((inputName) => ({ provide: inputName, useValue: data.inputs[inputName] }));
      const resolvedInput = ReflectiveInjector.resolve(inputProviders);

      const injector = ReflectiveInjector.fromResolvedProviders(resolvedInput, this.dynamicModalContainer.parentInjector);
      factory = this.resolver.resolveComponentFactory(data.component);
      component = factory.create(injector);

      this.dynamicModalContainer.clear();
      this.dynamicModalContainer.insert(component.hostView);
      component.changeDetectorRef.detectChanges();
    } else {
      factory = this.resolver.resolveComponentFactory(data.component);
      component = this.dynamicModalContainer.createComponent(factory);
      component.changeDetectorRef.detectChanges();
    }
    if (this.currentModalComponent) {
      this.currentModalComponent.destroy();
    }
    this.currentModalComponent = component;
  }

  constructor(private resolver: ComponentFactoryResolver) {

  }
}
