import { AfterViewInit, Component, Injector, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { DynamicModalService } from '../dynamic-modal/dynamic-modal.service';

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss']
})
export class CameraModalComponent implements OnInit, AfterViewInit {
  @ViewChild('modalWindow') pictureModal;
  onClose: Subject<Function>;

  @Output()
  public pictureTaken = new EventEmitter<WebcamImage>();
  public data: any = {};
  // toggle webcam on/off
  public showWebcam = true;
  public webcamImage: WebcamImage;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

  constructor(
    private readonly dynamicModalService: DynamicModalService,
    private readonly injector: Injector) { }

  public ngOnInit(): void {
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });
    this.onClose = this.injector.get('onClose');

  }

  ngAfterViewInit() {
    this.pictureModal.show();
    this.pictureModal.onHidden
        .subscribe(() => {
          this.showWebcam = false;
          this.dynamicModalService.modalOnClose.next(null);
        });
  }

  public takeSnapshot(): void {
    this.trigger.next();
  }

  public retakeSnapshot(): void {
    this.showWebcam = true;
    this.webcamImage = null;
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.showWebcam = false;
    this.pictureTaken.emit(webcamImage);
  }

  public sendBackSnapshot(): void {
    this.pictureTaken.emit(this.webcamImage);
  }  

  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  dataURLtoFile(dataurl, filename) {
 
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8Arr = new Uint8Array(n);
        
    while(n--){
        u8Arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8Arr], filename, {type:mime});
  }

  closeModal() {
    this.showWebcam = false;
    const imageName = Math.random().toString(36).slice(2);
    const file = this.dataURLtoFile(this.webcamImage.imageAsDataUrl, `${imageName}.jpeg`);
    this.data = {
      webcamImage: this.webcamImage,
      files: [file]
    }
    this.onClose.next(this.data);
    this.onClose.complete();
    this.pictureModal.hide();
  }
}
