import { AfterViewInit, Component, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'limo-import-error',
  templateUrl: 'limo-import-error.template.html'
})

export class LimoImportErrorModalComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('modalWindow222') limoImportModal;
  isFormSubmitted = false;
  onClose: Subject<any>;
  data: any;


  /**
   * Component Constructor.
   * @param formBuilder
   * @param injector
   */
  constructor(
    private injector: Injector
  ) {

  }



  /**
   * Init
   */
  ngOnInit() {
    this.onClose = this.injector.get('onClose');
    this.data = this.injector.get('data');
  }


  /**
   * After modal is initialized.
   */
  ngAfterViewInit() {
    this.limoImportModal.show();
  }


  /**
   * Close modal.
   */
  closeModal() {
    this.limoImportModal.hide();
  }



  /**
   * On destroy event.
   */
  ngOnDestroy() {
  }

}
