import { HttpErrorResponse } from '@angular/common/http';

export const HttpCode = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  INTERNAL_SERVER_ERROR: 500,
  REQUEST_ENTITY_TOO_LARGE: 413
};

export interface ApiError {
  title: string;
  detail?: string;
  code?: string;
  source?: {
    parameter: string;
  };
}

export interface ApiErrorResponse extends HttpErrorResponse {
  error: { errors: ApiError[] };
}

export interface CagApiErrorResponse extends HttpErrorResponse {
  error: {
    errorCode: string;
    fields: Object;
    message: string;
    operationMessage: string;
  };
}

export function isMessageInApiErrorResponse(response: any): response is CagApiErrorResponse {
  return response && response.error && response.error.message;
}
