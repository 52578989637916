import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Injector,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { omit } from 'lodash';
import { Select2OptionData } from 'ng-select2';
import { ModalDirective } from 'ngx-bootstrap';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { DateService, SpinnerService, ToastrService } from '../../';
import { TrainerManagementService } from '../../../trainer-management/trainer-management.service';
import { Day, Slot } from '../../../training-slots/slot';
import { TrainingSlotsService } from '../../../training-slots/training-slots.service';
import { daysOption, regex, slotModes, slotTypes } from '../../constants';
import { DatePickerOptions } from '../../global-interfaces';


@Component({
  selector: 'training-slot-modal',
  templateUrl: './training-slot.template.html',
  encapsulation: ViewEncapsulation.None
})
export class TrainingSlotModalComponent implements OnInit, AfterViewInit {
  @ViewChild('modalWindow') public modalWindow: ModalDirective;
  onClose: Subject<void>;

  trainingSlot: FormGroup;
  slot: Slot;

  trainerId: number | string;
  languageId: number | string;
  category_id: number | string;
  locationId: number | string;

  days: Array<Day> = [];
  trainers: Array<Select2OptionData> = [];
  languages: Array<Select2OptionData> = [];
  locations: Array<Select2OptionData> = [];
  modes: Array<Select2OptionData> = [];
  types: Array<Select2OptionData> = [];
  categories: Array<Select2OptionData> = [];

  startTimeOptions: DatePickerOptions = {
    autoclose: true,
    todayHighlight: true
  };

  endDateOptions: DatePickerOptions = {
    autoclose: true,
    todayHighlight: true
  };

  selectedDays: Array<number> = [];
  private trainersList: Array<any> = [];

  constructor(
    private trainerService: TrainerManagementService,
    private trainingSlotService: TrainingSlotsService,
    private spinnerService: SpinnerService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private dateService: DateService,
    private changeDetection: ChangeDetectorRef,
    private injector: Injector
  ) {
    this.days = daysOption;
    this.modes = slotModes;
    this.startTimeOptions.startDate = this.dateService.getCityDate();
    this.endDateOptions.startDate = this.dateService.getCityDate();
    this.types = slotTypes;
  }

  ngOnInit() {
    this.slot = this.injector.get('slot');
    this.onClose = this.injector.get('onClose');

    this.locations = this.injector.get('locations');
    this.languages = this.injector.get('languages');
    this.categories = [{ id: '0', text: 'All' }].concat(this.injector.get('formOptions'));
    this.trainers = this.mapTrainers(this.injector.get('trainers'));

    this.trainingSlot = this.formBuilder.group({
      startTime: [this.dateService.getCityDate(), Validators.required],
      startSlotTime: [this.slot.id ? new Date(this.slot.startTime) : undefined, Validators.required],
      capacity: [this.slot.capacity, [Validators.required, Validators.pattern(regex.capacity)]],
      languageId: [this.slot.id ? this.slot.language['id'] : this.languages.length ? this.languages[0].id : '', Validators.required],
      trainerId: [this.slot.id ? this.slot.trainer['id'] : '', Validators.required],
      locationId: [this.slot.id ? this.slot.location['id'] : this.locations.length ? this.locations[0].id : '', Validators.required],
      formOptionId: [this.slot.form_option ? this.slot.form_option['id'] : '0', Validators.required],
      type: ['single'],
      repeatType: [''],
      frequency: ['', Validators.pattern(regex.frequency)],
      endDate: ['']
    });

    if (this.trainers.length && !this.slot.id) {
      this.trainingSlot.patchValue({ trainerId: this.trainers[0].id });
    }
    if (this.locations.length && !this.slot.id) {
      this.trainingSlot.patchValue({ locationId: this.locations[0].id });
    }
    if (this.slot.id && this.slot.startTime) {
      this.trainingSlot.patchValue({ startTime: new Date(this.slot.startTime)});
    }
    if (this.slot.id && this.slot.startTime) {
      this.trainingSlot.patchValue({ startSlotTime: new Date(this.slot.startTime)});
    }


    this.locationId = this.trainingSlot?.get('locationId')?.value;
    this.languageId = this.trainingSlot?.get('languageId')?.value;
    this.category_id = this.trainingSlot?.get('formOptionId')?.value;
    this.trainerId = this.trainingSlot?.get('trainerId')?.value;
  }

  ngAfterViewInit() {
    this.modalWindow.show();
    this.changeDetection.detectChanges();
  }

  onChangeMode(event: string) {
    if (event === 'repeat') {
      this.trainingSlot?.patchValue({ repeatType: 'daily' });
      this.trainingSlot?.controls?.['endDate']?.setValidators(Validators.required);
    } else {
      this.trainingSlot?.patchValue({ repeatType: '' });
      this.trainingSlot?.controls?.['endDate']?.setErrors(null);
    }
    this.trainingSlot?.patchValue({ type: event });
  }

  onDayChange(day: number, event: boolean) {
    if (event) {
      return this.selectedDays.push(day);
    } this.selectedDays = this.selectedDays.filter((_day) => _day !== day);
  }

  onChangeForm(key: string, event: string) {
    this.trainingSlot?.controls?.[key]?.setValue(event);
    if (key === 'languageId') {
      this.trainingSlot?.controls?.['languageId']?.setValidators(Validators.required);
    }
    if (key === 'languageId' || key === 'locationId') {
      this.populateTrainersOnChange();
    }
  }

  onChangeStartTime(event: Date) {
    this.endDateOptions = Object.assign({}, this.endDateOptions, {
      startDate: this.dateService.getCityDate(event)
    });
  }

  onSubmit(slot: Slot) {
    if (this.trainingSlot?.get('type')?.value === 'repeat') {
      slot.repeat = {
        type: slot.repeatType,
        endDate: this.dateService.getEndOfDay(new Date(slot.endDate), false)
      };
      if (slot.repeat.type === 'weekly') {
        slot.repeat.frequency = slot.frequency;
        slot.repeat.days = this.selectedDays;
      }
    }
    slot = omit(slot, ['repeatType', 'endDate', 'frequency']);
    if (slot?.formOptionId === '0') {
      slot = omit(slot, 'formOptionId');
    }

    slot.startTime = this.dateService.getFullDateWithCityZone(this.dateService.mergeDateAndTime(new Date(slot.startTime), new Date(slot.startSlotTime)));

    this.spinnerService.change(true);
    if (this.slot?.id) {
      this.trainingSlotService.updateSlot(slot, this.slot.id)
        .subscribe(() => this.closeModal('update'));
    } else {
      this.trainingSlotService.createSlot(slot)
        .subscribe(() => this.closeModal('update'));
    }
  }

  closeModal(type?: string) {
    this.onClose?.next();
    this.spinnerService.change(false);
    this.toastrService.openToastr(`Successfully ${type} training slot`, 'success');
    this.modalWindow?.hide();
  }

  mapTrainers(trainers: Array<any>): Array<any> {
    this.trainersList = trainers;
    return this.trainersList.map(trainer => {
      return {
        id: trainer?.userId,
        text: `${trainer?.user?.firstName} ${trainer?.user?.lastName}`
      };
    });
  }

  populateTrainersOnChange() {
    this.spinnerService.change(true);
    this.trainerService.getAllTrainers(this.trainingSlot?.get('languageId')?.value, this.trainingSlot?.get('locationId')?.value).pipe(
      map((response: any[]) => this.mapTrainers(response)))
      .subscribe((trainers) => {
        this.spinnerService.change(false);
        this.trainers = trainers || [];
        if (this.trainers.length) {
          this.trainerId = trainers[0].id;
        }
      });
  }
}
