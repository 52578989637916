import { Select2OptionData } from 'ng-select2';
import { CaptainLead } from '../overview-v2/models';

export interface DatePickerOptions {
    startDate?: Date;
    endDate?: Date;
    autoclose?: boolean;
    todayBtn?: string;
    todayHighlight?: boolean;
    assumeNearbyYear?: boolean;
    format?: string;
    defaultViewDate?: Date;
}

export interface SelectChange {
    value: string;
}

export interface CountryPhone {
    id: number;
    dialCode?: string;
    dial_code?:string
    phoneNumberRegex: string;
    flagUrl?: string;
}

export interface Select2LanguageOption extends Select2OptionData {
    selected: boolean;
}

export interface DownloadFileResponse {
    file_name: string;
    message: string;
    url: string;
}

export type LocationType = 'captain' | 'car' | 'online';
export const LocationTypeEnum = {
    Captain: 'captain' as LocationType,
    Car: 'car' as LocationType,
    Online: 'online' as LocationType
};

export type RoleGroup = 'country_admin' | 'franchise_admin' | 'franchise_agent' | 'super_admin' | 'trainer' | 'audit_agent' | 'preprocessing_agent';
export const RoleGroupEnum = {
    CountryAdmin: 'country_admin' as RoleGroup,
    FranchiseAdmin: 'franchise_admin' as RoleGroup,
    FranchiseAgent: 'franchise_agent' as RoleGroup,
    SuperAdmin: 'super_admin' as RoleGroup,
    Trainer: 'trainer' as RoleGroup,
    AuditingAgent: 'auditing_agent' as RoleGroup,
    PreprocessingAgent: 'preprocessing_agent' as RoleGroup
};

// TODO: After updating to typescript > 2.4
// export enum LocationType {
//     Captain = 'captain',
//     Car = 'car',
//     Online = 'online'
// }

export class Captain {
    id: number;
    profile?: {
        email: string;
        firstName: string;
        lastName: string;
        cellPhone: string;
        createdAt: number;
        profilePicture: string;
        cityId: number;
        cityName: string;
    };

    get fullName(): string {
        return CaptainLead.getFullName(this.profile.firstName, this.profile.lastName);
    }

    constructor(json: Captain) {
        this.id = json.id;
        this.profile = json.profile;
    }
}

export type OtpServiceType = 'SMS' | 'Voice';

export interface OtpResponse {
    token?: string;
    response?: {
        message?: string
    };
}

export interface Select2OptionData2 {
    id: number;
    text: string;
    disabled?: boolean;
    children?: Array<Select2OptionData>;
    additional?: any;
}
