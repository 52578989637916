<div bsModal #modalWindow="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="add_limo_company_modal" aria-hidden="true">
    <div class="modal-dialog check-car-modal">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title text-xs-center fw-bold mt" id="myModalLabel18">Car Check</h4>
                <button (click)="modalWindow.hide()" aria-label="Close" class="close" type="button">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="widget-body">
                    <div class="table-responsive">
                        <table class="table table-striped table-hover"
                                [mfData]="cars" #mf="mfDataTable"
                                [mfRowsOnPage]="10">
                                <caption></caption>
                            <thead>
                                <tr>
                                    <th scope="col">
                                        <span>Registration Number</span>
                                    </th>
                                    <th scope="col">
                                        <span>Status</span>
                                    </th>
                                    <th scope="col">
                                        <span>Current Status</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let car of mf.data">
                                    <td><span class="">{{car.registration}}</span></td>
                                    <td>
                                        <button class="status-buttons no-border" (click)="setStatus(car, status.approve)">
                                            <i class="fa fa-check {{car.status.id === 2 ? 'active' : ''}}"></i></button>
                                        <button class="status-buttons no-border" (click)="setStatus(car, status.reject)">
                                            <i class="fa fa-times {{car.status.id === 3 ? 'active' : ''}}"></i></button>
                                    </td>
                                    <td><span>{{car?.status?.title}}</span></td>
                                </tr>
                                <tr *ngIf="!cars?.length">
                                <td colspan="100">
                                    No records
                                </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div *ngIf="cars?.length" class="modal-footer">
                <button (click)="updateCars()" class="btn btn-success">Update Status</button>
            </div>
        </div>
    </div>
</div>
