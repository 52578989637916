import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HEADER_KEYS, V2_HEADER } from '../overview-v2/document-transcription-v2/shared/constants';

@Injectable()
export class CountriesService {
  headers: { [key: string]: any } = {};

  constructor(private readonly httpClient: HttpClient) {
    this.headers = {
      [V2_HEADER]: "1",
      [HEADER_KEYS["auth-token-update"]]: "1",
      [HEADER_KEYS["sg-auth-token"]]: "1",
      ["x-city-id"]: localStorage.getItem("sg_city_id") || "",
    };
  }

  getCountryList(showAll = false, isRevampVersion = false): Observable<any> {
    const showAllParam = showAll ? "showall=1" : "";
    const url = isRevampVersion ? `/countries` : `countries?${showAllParam}`;
    const headers = {
      ...(isRevampVersion && {
        ...this.headers
      })
    };
    return this.httpClient.get(url, { headers });
  }
}
