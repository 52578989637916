import { Routes } from '@angular/router';

// Components
import { ErrorComponent } from './common/error/error.component';

// Injectors
import { AuthGuard } from './common';
import { AuthErrorComponent } from './common/auth/auth-error/auth-error.component';
import { HealthCheckComponent } from './health-check/health-check.component';

export const ROUTES: Routes = [
  { path: '', canActivate: [AuthGuard], loadChildren: () => import('./shell/shell.module').then(m => m.ShellModule) },
  { path: 'health-check', component: HealthCheckComponent },
  { path: 'auth-error', component: AuthErrorComponent },
  { path: 'error', component: ErrorComponent },
  { path: '**', component: ErrorComponent },
];
