import { Select2OptionData } from 'ng-select2';

export const progressStatus: Array<any> = [
  {id: '1', text: 'Pending Transcription', key: 'transcription'},
  {id: '2', text: 'Pending Training', key: 'training'},
  {id: '3', text: 'Pending Car Check', key: 'carCheck'},
  {id: '4', text: 'Downloaded/Available for Download', key: 'download'}
];

export const statuses: Array<Select2OptionData> = [
  {id: '2', text: 'Approved'},
  {id: '3', text: 'Rejected'},
  {id: '6', text: 'In Process'},
  {id: '4', text: 'Resubmission'},
  {id: '5', text: 'Retraining'}
];

export const messages = {
  successfulCheckIn: 'Successfully checked in user',
  unsuccessfulCheckIn: 'Cannot Check in User, Make sure the training has started'
};

export const statusValues = {
  transcription: {
    approved: { status: 4, message: 'Approved' },
    rejected: { status: 3, message: 'Rejected' },
    resubmission: { status: 2, message: 'Resubmission' },
    pending: { status: 1, message: 'In Progress' }
  },
  training: {
    rejected: { status: 3, message: 'Failed' },
    approved: { status: 2, message: 'Passed' },
    retraining: { status: 4, message: 'Rescheduled' },
    scheduled: { status: 1, message: 'Scheduled' }
  },
  carCheck: {
    rejected: { status: 3, message: 'Rejected' },
    approved: { status: 2, message: 'Approved' },
    pending: { status: 1, message: 'Pending' }
  },
  download: {
    yes: { status: 1, message: 'Downloaded' },
    no: { status: 0, message: 'Available' }
  }
};

export const trainingLocation = {
  online: 4
};

export const progressValues = {
  incomplete: 1,
  approved: 2,
  rejected: 3,
  resubmission: 4,
  retraining: 5,
  inProcess: 6
};
