import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Select2OptionData } from 'ng-select2';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { QueryParams } from './user';
import { HEADER_KEYS, V2_HEADER } from '../overview-v2/document-transcription-v2/shared/constants';

@Injectable()
export class UserManagementService {
  headers: {[key: string]: any} = {};

  constructor(
    private readonly httpClient: HttpClient
  ) {
    this.headers = {
      [V2_HEADER]: '1',
      [HEADER_KEYS['auth-token-update']]: '1',
      [HEADER_KEYS['sg-auth-token']]: '1',
      ['x-city-id']: localStorage.getItem('sg_city_id') || '',
    }
  }

  getUsers(params: QueryParams, isRevampVersion = false): Observable<any> {
    let args = new HttpParams();

    if(isRevampVersion) {
      args = args.set('page', String(params.current_page))
        .set('sortBy', String(params.sort_by))
        .set('sortOrder', String(params.sort_order))
    } else {
      args = args.set('page', String(params.current_page))
      .set('sort_by', String(params.sort_by))
      .set('sort_order', String(params.sort_order))
    }

    if (params.search_text) {
      args = args.set('search', String(params.search_text));
    }
    if (params.role_id > 0) {
      const roldIdInstanceName = isRevampVersion ? 'roleId' : 'role_id';
      args = args.set(roldIdInstanceName, String(params.role_id) ? String(params.role_id) : '');
    }

    const url = isRevampVersion ? '/admin' : 'admin';
    const headers = {
      ...(isRevampVersion && {
        ...this.headers
      })
    }
    return this.httpClient.get(url, { params: args, headers });
  }

  getUser(userid: number): Observable<any> {
    return this.httpClient.get(`admin/${userid}`);
  }

  addUser(user: any, isRevampVersion = false): Observable<any> {
    const url = isRevampVersion ? '/admin' : 'admin';
    const headers = {
      ...(isRevampVersion && {
        ...this.headers
      })
    };
    return this.httpClient.post(url, user, { headers });
  }

  deleteUser(id: number, type: string, isRevampVersion = false): Observable<any> {
    const url = isRevampVersion ? `/${type}/${id}` : `${type}/${id}`;
    const headers = {
      ...(isRevampVersion && {
        ...this.headers
      })
    };
    return this.httpClient.delete(url, { headers });
  }

  updateUser(user: any, id: number, isRevampedVersion = false): Observable<any> {
    const url = isRevampedVersion ? `/admin/${id}` : `admin/${id}`;
    const headers = {
      ...(isRevampedVersion && {
        ...this.headers
      })
    };
    return this.httpClient.put(url, user, { headers });
  }

  getRoles(isRevampVersion = false): Observable<any> {
    const url = isRevampVersion ? '/admin/allowed-roles' : 'admin-allowed-roles';
    return this.httpClient.get(url, {
      headers: {
        ...(isRevampVersion && {
          ...this.headers
        })
      }
    }).pipe(
      map(res => res['data']
      ));
  }

  getCities(id: number): Observable<Select2OptionData[]> {
    return this.httpClient.get(`cities/${id}/siblings`).pipe(
    map((res) => res['data'].map(city => ({
        id: city.id,
        text: city.name
      }))));
  }

  getCaptainTypes() {
    return this.httpClient.get('/captain-types/all', {
      headers: this.headers
    }).pipe(
      map((res) => res['data'].map(captainType => ({
          id: captainType?.id,
          text: captainType?.title_key
        }))));
  }
}
