import { AbstractControl, ValidatorFn } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

export const validationRegex = {
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  password: /^(?=.*?[a-zA-Z])(?=.*?[0-9]).{6,}$/
};

export const validationError = {
  required: 'Please fill the required fields',
  pattern: 'Password must contain atleast one alphabet and a number',
  mismatch: 'Password mismatch'
};

export const emailValidationErrors = {
  required: 'Email is required',
  pattern: 'Please enter a valid email address'
};

export function requiredValidation(err, field) {
  return `This field is Required`;
}
export function minLengthValidation(err, field) {
  const length = field.validations ? field.validations.min_length : field.templateOptions.minLength;
  return `This field should be atleast ${length} characters`;
}
export function maxLengthValidation(err, field) {
  const length = field.validations ? field.validations.max_length : field.templateOptions.maxLength;
  return `This field should be atmost ${length} characters`;
}
export function minValidation(err, field) {
  const length = field.validations ? field.validations.min : field.templateOptions.min;
  return `This field should be greater than or equals to ${length}`;
}
export function maxValidation(err, field) {
  const length = field.validations ? field.validations.max : field.templateOptions.max;
  return `This field should be less than or equals to ${length}`;
}
export function regexValidation(err, field) {
  return `Field input is not valid`;
}
export function cnicExistsValidation() {
  return `This National ID number already belongs to an existing captain`;
}
export function fileNotUploaded(err, field) {
  return `This file is not uploaded`;
}
export function asycnValidation(err, field: FormlyFieldConfig) {
  return field['asycnValidatorErrorMessage'] || `Async Validation Failed`;
}

export function notEqualControl(value: number): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} => {
    const name = control.value;
    return value !== control.value ? {'notEqual': {name}} : null;
  };
}

