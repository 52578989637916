import { Injectable } from '@angular/core';

declare let ga: Function;

export const DIMENSIONS = {
  admin: 'dimension2',
  country: 'dimension3',
  city: 'dimension4',
  franchise: 'dimension5',
  entity: 'dimension10',
  requestStatus: 'dimension11'
};

export const METRICS = {
  duration: 'metric1'
};

type CustomDimensions = {
  [key in keyof typeof DIMENSIONS]?: string | number
};

type CustomMetrics = {
  [key in keyof typeof METRICS]?: string | number
}

export interface AnalyticsEvent {
  eventCategory: string;
  eventAction: string;
  eventLabel?: string;
  eventValue?: number;
  customDimensions?: CustomDimensions;
  customMetrics?: CustomMetrics;
}

@Injectable()
export class AnalyticsService {

  public sendEvent(event: AnalyticsEvent): void {
    const gaEvent = {
      eventCategory: event.eventCategory,
      eventAction: event.eventAction,
      eventLabel: event.eventLabel,
      eventValue: event.eventValue
    };

    Object.keys(event.customDimensions || { })
      .filter(key => event.customDimensions[key])
      .forEach(key => gaEvent[DIMENSIONS[key]] = event.customDimensions[key]);

    Object.keys(event.customMetrics || { })
      .filter(key => event.customMetrics[key])
      .forEach(key => gaEvent[METRICS[key]] = event.customMetrics[key]);

    ga('send', 'event', gaEvent);
  }

}
