<label class="clearfix">{{options.displayName}} </label>
<span class="upload-file-input-button">
<span *ngIf="!file?.data" class="btn btn-default btn-file add-cnic">
  <i class="zmdi zmdi-picture-in-picture"></i>
  Upload {{options.displayName}} - Optional
  <input type="file" (change)="onFileUpload($event)">
</span>
</span>

<div *ngIf="file?.data" class="uploader">
  <img *ngIf="!isPdf" class="file-upload" (click)="previewImage()" [src]="file?.data" alt="Uploaded File">
  <img *ngIf="isPdf" class="file-upload" (click)="previewImage()" [src]="file?.preview" alt="Uploaded File">
  <div *ngIf="options?.confirmation?.ask">
    <button class="image-close-icon pull-right"
            tooltip="Remove"
            mwlConfirmationPopover
            [title]="'Upload File'"
            [popoverMessage]="options?.confirmation?.message"
            placement="left" (confirm)="clearImage()"><i class="fa fa-times"></i></button>
  </div>
  <div *ngIf="!options?.confirmation?.ask">
    <button class="image-close-icon pull-right"
            tooltip="Remove"
            placement="left" (click)="clearImage()"><i class="fa fa-times"></i></button>
  </div>
</div>

<div class="progress">
  <div class="progress-bar" role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100">
    <span class="sr-only">60% Complete</span>
  </div>
</div>
