<!--Modal Template START-->
<div bsModal #modalWindow="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="user_modal" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title text-xs-center fw-bold mt" id="myModalLabel18">{{user.user_id ? 'Update' : 'Add a New'}} User</h4>
        <button (click)="modalWindow.hide()" aria-label="Close" class="close" type="button">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <form *ngIf="newUser" [formGroup]="newUser" novalidate (ngSubmit)="onSubmit(newUser.value)">
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label class="label-header fw-semi-bold">Role</label>
                <div>
                  <ng-select2 [data]="roles" [options]="{ theme: 'bootstrap4'}" [width]="'100%'" (valueChanged)="onChangeRoles($event)" [value]="roleId">
                  </ng-select2>
                </div>
                <div>
                </div>
                <span class="error" *ngIf="newUser.controls[role_id_key].hasError('required') && newUser.controls[role_id_key].dirty">This field is required<br/></span>
              </div>
            </div>
            <div class="col-md-6" *ngIf="newUser.controls?.['captainTypes']">
              <div class="form-group">
                <label for="captain-types-select" class="label-header fw-semi-bold">Captain Types</label>
                <div>
                  <ng-select2 id="captain-types-select" (valueChanged)="onChangeCaptainTypes($event)" [data]="captainTypes" [options]="select2Options" [width]="'100%'" [value]="selectedCaptainTypes"></ng-select2>
                </div>
                <span class="error" *ngIf="newUser.controls['captainTypes']?.hasError('required') && newUser.controls['captainTypes']?.dirty">This field is required</span>
              </div>
            </div>
            <div class="col-md-6" *ngIf="newUser.controls['countries']">
              <div class="form-group">
                <label for="country-select" class="label-header fw-semi-bold">Country</label>
                <div>
                  <ng-select2 id="country-select" (valueChanged)="onChangeCountry($event)" [data]="countryList" [options]="select2Options" [width]="'100%'" [value]="selectedCountries"></ng-select2>
                </div>
                <span class="error" *ngIf="newUser.controls['countries'].hasError('required') && newUser.controls['countries'].dirty">This field is required</span>
              </div>
            </div>
            <div class="col-md-6" *ngIf="newUser.controls['cities']">
              <div class="form-group">
                <label for="city-select" class="label-header fw-semi-bold">City</label>
                <div>
                  <ng-select2 id="city-select" (valueChanged)="onChangeCities($event)" [data]="cities" [options]="select2Options" [disabled]="isCityDisabled"
                    [width]="'100%'" [value]="selectedCities"></ng-select2>
                </div>
                <span class="error" *ngIf="newUser.controls['cities'].hasError('required') && newUser.controls['cities'].dirty">This field is required</span>
              </div>
            </div>
            <div class="col-md-6" *ngIf="newUser.controls['locations']">
              <div class="form-group">
                <label for="location-select" class="label-header fw-semi-bold">{{ locationsLabel }}</label>
                <div *ngIf="shouldSelectedMultipleLocations">
                  <ng-select2 id="location-select" class="height-hot-fix" [value]="selectedLocations" (valueChanged)="onChangeLocation($event)"
                    [data]="locations" [options]="select2Options" [disabled]="isTrainingScheduled" [width]="'100%'"></ng-select2>
                </div>
                <div *ngIf="!shouldSelectedMultipleLocations">
                  <ng-select2 id="location-select" [value]="selectedLocations" (valueChanged)="onChangeLocation($event)"
                    [data]="locations" [options]="{ theme: 'bootstrap4'}" [disabled]="isTrainingScheduled" [width]="'100%'"></ng-select2>
                </div>
                <span class="error" *ngIf="newUser.controls['locations'].hasError('required') && newUser.controls['locations'].dirty">This field is required</span>
                <span class="small" *ngIf="isTrainingScheduled">Location cannot be changed, because you currently have future slots</span>
              </div>
            </div>
            <div class="col-md-6" *ngIf="newUser.controls['languages']">
              <div class="form-group">
                <label class="label-header fw-semi-bold">Languages</label>
                <div>
                  <ng-select2 (valueChanged)="onChangeLanguage($event)" [data]="languages" [options]="select2Options" [width]="'100%'" [value]="selectedLanguages"></ng-select2>
                </div>
                <span class="error" *ngIf="newUser.controls['languages'].hasError('required') && newUser.controls['languages'].dirty">This field is required</span>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="email" class="label-header fw-semi-bold">Email</label>
                <input type="email" [readonly]="user.user_id" class="form-control" formControlName="email" placeholder="Email" id="email">
                <span class="error" *ngIf="newUser.controls['email'].hasError('required') && newUser.controls['email'].dirty">This field is required<br/></span>
                <span class="error" *ngIf="newUser.controls['email'].touched && newUser.controls['email'].hasError('pattern')">Not a valid email pattern</span>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-success" [disabled]="!newUser.valid || setSubmitted">
              {{!user.user_id ? 'Create' : 'Update'}} User
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!--Modal Template END-->
