import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CacheService } from './common/cache.service';

import { BaseUriInterceptor } from './common/interceptors/base-uri.interceptor';
import { CacheInterceptor } from './common/interceptors/cache.interceptor';
import { CityInterceptor } from './common/interceptors/city.interceptor';
import { ErrorInterceptor } from './common/interceptors/error.interceptor';
import { TokenInterceptor } from './common/interceptors/token.interceptor';
import { StorageService } from './common/storage.service';

export const INTERCEPTORS = [
  StorageService,
  CacheService,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: BaseUriInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: CityInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: CacheInterceptor,
    multi: true
  }
];
