import { AfterViewInit, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'prompt',
  templateUrl: 'prompt.template.html'
})

export class PromptComponent implements OnInit, AfterViewInit {
  @ViewChild('modalWindow') promptModal;

  onClose: Subject<any>;
  title: string;
  closeStatus: boolean;
  message: string;
  confirmButton: string;
  cancelButton: string;

  constructor(
    private injector: Injector
  ) {}

  hideModal() {
    this.onClose.next(this.closeStatus);
    this.onClose.complete();
  }

  ngOnInit() {
    this.onClose = this.injector.get('onClose');
    this.title = this.injector.get('title');
    this.message = this.injector.get('message');
    this.confirmButton = this.injector.get('confirmButton');
    try { this.cancelButton = this.injector.get('cancelButton'); } catch (e) { }
  }

  ngAfterViewInit() {
    this.promptModal.show();
  }

  closeModal(data?: boolean) {
    this.closeStatus = data;
    this.promptModal.hide();
  }
}
