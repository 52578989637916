import { HttpBackend, HttpClient, HttpEvent, HttpHeaders,
  HttpInterceptor, HttpRequest, HttpErrorResponse, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { first, mergeMap, filter } from 'rxjs/operators';

import { AuthService } from '../auth/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private readonly httpClient: HttpClient;
  private readonly token$ = new Subject<void | HttpErrorResponse>();
  private refreshTokenSubscription: Subscription;

  constructor(
    private readonly authService: AuthService,
    httpBackend: HttpBackend
  ) {
    this.httpClient = new HttpClient(httpBackend);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.startsWith('http')) return next.handle(request);
    return this.fetchToken(request).pipe(
      first(),
      filter(res => !(res instanceof HttpErrorResponse)),
      mergeMap((token?: string) => {
        request = request.clone({
          headers: this.isRequestForCaptainGateway(request) ?
          this.getHeadersForCaptainGateway(request, token) : this.getHeadersForServices(request)
        });
        return next.handle(request);
      })
    );
  }

  private fetchToken(request: HttpRequest<any>): Observable<any> {
    const isLogoutRequest = request.url.indexOf('supply/gate/logout') >= 0;
    if (!this.authService.isSGTokenExpired() || isLogoutRequest) return of(null);
    if (!this.refreshTokenSubscription) {
      this.refreshTokenSubscription = this.getRefreshedToken()
        .subscribe((res: any) => {
          this.refreshTokenSubscription = null;
          this.authService.saveToken(res);
          this.token$.next();
        }, (err: HttpErrorResponse) => {
          this.token$.next(err);
          this.authService.logout();
        });
    }
    return this.token$.asObservable();
  }

  private isRequestForCaptainGateway(request: HttpRequest<any>) {
    return request.url.indexOf('supply/gate') >= 0;
  }

  private getRefreshedToken() {
    return this.httpClient.post('/api/supply/gate/token/refresh', null, {
      headers: new HttpHeaders(this.authService.getHeadersForCaptainGateway())
    });
  }

  private getHeadersForCaptainGateway(request: HttpRequest<any>, authToken?: string) {
    return this.getRequestHeaders(request, this.authService.getHeadersForCaptainGateway(authToken));
  }

  private getHeadersForServices(request: HttpRequest<any>) {
    const authTokenUpdate = request.headers.get('auth-token-update');
    const sgAuthTokenCheck = request.headers.get('sg-auth-token');
    return this.getRequestHeaders(request, this.authService.getHeadersForServices(!!authTokenUpdate, !!sgAuthTokenCheck));
  }

  private getRequestHeaders(request: HttpRequest<any>, headers: any) {
    return Object.keys(headers)
      .reduce((requestHeaders, key) => requestHeaders.set(key, headers[key]), request.headers);
  }
}
