import { HttpParams } from '@angular/common/http';

/**
 * If a http request used an instance of this extended class to provide parameters then we want to log this request in
 * case of errors
 */
export class InterceptorLoggedHttpParams extends HttpParams {
  constructor(
    public interceptorConfig: InterceptorRequestConfig,
    params?: { [param: string]: string | string[] }
  ) {
    super({ fromObject: params });
  }
}

export interface InterceptorRequestConfig {
  errorSource?: string;   // Where did the error happen? Can be used later to filter for specific error sources
  customAttributes?: {};  // All additional attributes that should be logged
}
