<!--Modal Template START-->
<div bsModal #modalWindow="bs-modal" 
  [config]="{ show: false }"
  class="modal fade" tabindex="-1" role="dialog" aria-labelledby="trainer_modal" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title text-xs-center fw-bold mt" id="myModalLabel18">Take Picture</h4>
        <button (click)="modalWindow.hide()" aria-label="Close" class="close" type="button">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="!errors.length" style="text-align:center">
          <div>
            <webcam [height]="450" [width]="450" [trigger]="triggerObservable" (imageCapture)="handleImage($event)" *ngIf="showWebcam"
             [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable" [videoOptions]="videoOptions" (cameraSwitched)="cameraWasSwitched($event)"
             (initError)="handleInitError($event)"></webcam>
          </div>
          <div class="snapshot" *ngIf="webcamImage">
            <img [src]="webcamImage.imageAsDataUrl" alt="webcam_image" />
          </div>

        </div>
        
        <div class="text-danger" *ngIf="errors.length > 0">Please allow camera to take picture</div>
        <ul hidden *ngFor="let error of errors">
          <li>{{error | json}}</li>
        </ul>
      </div>
      <div class="modal-footer">
        <button *ngIf="!showWebcam" class="btn btn-outline-success" tabindex="-1" (click)="retakeSnapshot()" [disabled]="onLoading">
          Re-take Picture
        </button>
        <button *ngIf="!showWebcam" class="btn btn-success" tabindex="-1" (click)="closeModal()" [disabled]="onLoading">
          Ok
        </button>
        <!-- <button *ngIf="!webcamImage" class="btn btn-outline-success" tabindex="-1" (click)="showNextWebcam(true)" [disabled]="onLoading">
          Switch Camera
        </button> -->
        <button *ngIf="showWebcam && !errors.length" class="btn btn-success" tabindex="-1" (click)="takeSnapshot()" [disabled]="onLoading">
          Take Picture
        </button>
      </div>
    </div>
  </div>
</div>
<!--Modal Template END-->
