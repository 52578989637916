import { Select2OptionData } from 'ng-select2';

export const daysOption = [
  { id: 0, text: 'Sun' },
  { id: 1, text: 'Mon' },
  { id: 2, text: 'Tue' },
  { id: 3, text: 'Wed' },
  { id: 4, text: 'Thu' },
  { id: 5, text: 'Fri' },
  { id: 6, text: 'Sat' }
];

export const hijriDateConfig = {
  startYear: 65,
  endYear: 65
};

export const gregorianDateConfig = {
  minAge: 15,
  maxAge: 80
}

export const selectAllOption = [{ id: '0', text: 'All' }];

export const slotModes = [
  { id: 'single', text: 'One time' },
  { id: 'repeat', text: 'Repeat' }
];

export const slotTypes = [
  { id: 'daily', text: 'Daily' },
  { id: 'weekly', text: 'Weekly' },
  { id: 'monthly', text: 'Monthly' }
];

export const regex = {
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  name: /^[ A-Za-z./`'-]*$$/i,
  capacity: /^([1-9][0-9]{0,2}|1000)$/,
  frequency: /^([1-9]|[1]\d|2[0-5])$/
};

export const fileSizes = {
  maxLimit: 12 * 1000 * 1000, // 12 mb max size
  megaByte: 1000 * 1000,
  kilobyte: 1000
};

export const userRoles = [
  { id: 1, text: 'Super Admin' },
  { id: 7, text: 'City Admin' },
  { id: 3, text: 'Transcriptor' }
];

export const FRANCHISE_ROLES = ['franchise_admin', 'franchise_agent']; // roles table name column

export const VEHICLE_OVERVIEW_PARTS = {
  part: 'PROFILE,STATUS,ACTIONS_APPLICABLE,MAKE,MODEL'
};

export const SUPPLIER_OVERVIEW_PARTS = {
  part: 'ALL'
};

export const VEHICLE_TRANSCRIPTION_PARTS = {
  part: 'LIMO,DEDICATED_CAPTAIN_LEAD'
};

export const userGroups = {
  trainer: 'trainer',
  admin: 'admin'
};

export const trainingType: Array<Select2OptionData> = [
  { id: '0', text: 'All' },
  { id: '1', text: 'Online' }
];

export const reportType = {
  incompleteProfile: 'Download Incomplete Profile',
  completeProfile: 'Download Complete Profile',
  allCaptains: 'Download All Captains',
  allReports: 'Download Reports',
  optionalDocuments: 'Download Optional Documents',
  helpLeads: 'Download Help Leads',
  captains: 'Download Captains'
};

export const buttonText = 'go to download history';

export const fileStatus = {
  pending: 0,
  generating: 1,
  completed: 2
};

export enum PROFILE_STATUS {
  INCOMPLETE = 1,
  APPROVED = 2,
  REJECTED = 3,
  RESUBMISSION = 4,
  RETRAINING = 5,
  IN_PROCESS = 6
}

export const ToastrMessages = {
  success: 'success',
  error: 'error',
  passwordUpdatedSuccess: 'Password updated successfully',
  noRecords: 'No more records available',
  recordUpdated: 'Record Updated Successfully',
  captainUpdated: 'Captain updated successfully',
  approvedCar: 'Approved Car Successfully',
  rejectedCar: 'Rejected Car Successfully',
  requestLarge: 'Could not complete operation. Request too large',
  requireImageFile: 'File Should be jpeg / png / jpg / gif',
  supportedFormats: 'Supported file formats are [.jpeg, .jpg, .png, .pdf]',
  fileSizeLimit: 'File size cannot be greater than 12MB',
  addedLimoSuccess: 'Limo Company Created successfully',
  addedVehicleSuccess: 'Vehicle created successfully',
  addedSupplierSuccess: 'Supplier created successfully',
  noSlots: 'No slot available for the provided date, location and language',
  userMovedSuccess: 'Successfully moved users and removed training',
  carStatusChanged: 'Car Status Successfully Updated',
  citySame: 'The Captain City is same as before',
  importCompaniesSuccess: 'Companies Imported Successfully',
  carStatuesUpdated: 'Car Status(es) updated successfully',
  trainerRemovedSuccess: 'Successfully removed trainer',
  trainingStarted: 'Training has started',
  trainingEnded: 'Training has ended',
  trainingRemoved: 'Successfully removed training',
  removedUserSuccess: 'Successfully removed user',
  cityUpdateSuccess: 'City updated successfully',
  captainAccountExistsError: 'A CaptainID (ADMA account) already exists for this Captain',
  sendOtpToCaptainForProfileValidationError: 'Existing captain profile detected. Unable to send OTP to Captain for his profile validation. Please try again later',
  captainAlreadyApproved: 'Captain is already approved, and ready to have an ADMA account',
  noCompaniesFound: 'No companies found matching your criteria',
  noValidSupplierSearchFormValues: 'Provided phone number or email is invalid',
  enableForMarketFirst: 'Document must be enabled for this market first',
  cmsNotSupported: 'The CMS feature is currently not supported for this city.',
  cmsCityStatusDoesNotExist: 'Something went wrong while checking if selected city does exist in local store',
  noUserFound: 'No users found matching your criteria',
  noPhoneAgainstUser: 'No phone number associated with this customer!',
  otpSendError: 'Error sending OTP to phone number'
};

export const DOCUMENT_UPLOAD_VIA = {
  FILE: 1,
  WEBCAM: 2
}

export const BLOCKING_STATUS_COLOR_CODES = {
  "Permanent Blocked": "#d80005",
  "Temporary Blocked": "#f56e00",
  "Active": "#28bb4e",
  "Probation": "#4285f4",
}

export const UNBLOCKING_MODES = {
  AUTOMATIC_UNBLOCK: "Will be unblocked automatically after the time mentioned in the comment and also shown to the Captain on home screen",
  ONLINE_TRAINING: "Online re-training needs to be done. A link is sent to the Captain in a push notification, can also be accessed from the ADMA / IDMA homescreen. Will be immediately unblocked after completing the training and passing a test",
  OFFLINE_TRAINING_IN_BOUND: "Captain needs to come to the office / OC and take a manual training",
  OFFLINE_TRAINING_OUT_BOUND: "Captain will receive a call from Careem for a training",
  DOCUMENT_UPLOAD: "Captain needs to upload up-to-date valid documents through Captain Portal. Will be unblocked within 1-2 working days after verification",
  CAR_INSPECTION: "Captain needs to check his messages for the exact instructions on where to take the vehicle for inspection and next steps",
  MANUAL_IN_BOUND: "Captain needs to visit the OC / Careem office, understand the issue, get the training (if required) and a ticket will be raised for him to get unblocked. Will be unblocked within 1-2 days",
  MANUAL_OUT_BOUND: "Captain will receive a call from Careem for the next steps",
}

export enum SG_ADMIN_CONSOLE_EVENTS {
  SG_FE_viewed = 'SG_FE_viewed',
  SG_FE_CTA_clicked = 'SG_FE_CTA_clicked',
  'SG_FE_closed/cancelled' = 'SG_FE_closed/cancelled',
  SG_FE_error = 'SG_FE_error',
  DOCUMENT_APPROVAL_VIEW = 'DOCUMENT_APPROVAL_VIEW',
  DOCUMENT_APPROVAL_VIEW_RE_INIT = 'DOCUMENT_APPROVAL_VIEW_RE_INIT',
  DOCUMENT_APPROVAL_SAVE_NEXT = 'DOCUMENT_APPROVAL_SAVE_NEXT',
  DOCUMENT_APPROVAL_SKIP = 'DOCUMENT_APPROVAL_SKIP',
  DOCUMENT_APPROVAL_SAVE_ERROR = 'DOCUMENT_APPROVAL_SAVE_ERROR'
}

export const screens: { [key: string]: string } = {
  CREATE_TICKET: 'create_ticket_page',
  TICKET_HISTORY: 'ticket_history_page',
  DOCUMENT_APPROVAL: 'document_approval_page',
}

export const events: { [key: string]: string } = {
  PAGE_OPEN: 'page_open',
  BLOCK_HISTORY_CLICKED: 'block_history_clicked'
}

export const PROJECT_NAME = 'supply_gate_admin_console';

export enum SG_GALILEO_VARIABLES {
 BLOCKING_HISTORY = 'blocking_history',
 DOCUMENT_TRANSCRIPTION_FLOW_REVAMP = 'document_transcription_flow_revamp',
 user_management_revamp = 'user_management_revamp',
 add_lead_revamp = 'add_lead_revamp',
}

export const APPROVAL_STATUS = 2;

export const  ANALYTIKA_SESSION_ID = '_sid';

export const INACTIVE_TIMEOUT = 30 * 60 * 1000; // specifically used for session timeout which is defined to 30 minutes. This format will help to understand the time

export enum ROUTES_URLS {
  'document-transcription-v2' = 'document-transcription-v2'
}

export const HEADER_TRUTHY_VALUE = '1';

export const SERVICE_NAME = 'sg_admin_console';

export enum BUTTONS {
  doc_approval_save_next = 'doc_approval_save_next',
  doc_approval_skip = 'doc_approval_skip',
};

export enum ANALYTIKA_V2_EVENTS {
  acqu_view_page = 'acqu_view_page',
  acqu_view_init_page = 'acqu_view_init_page',
  acqu_press_button = 'acqu_press_button',
};

export const ANALYTIKA_V2_EVENT_DETAILS = {
  [ANALYTIKA_V2_EVENTS.acqu_view_page]: {
    VERSION: 1,
    SIGNATURE: 'AQEDEyB8AAAAAAAAALepwbVm9ENjmtYAtI6bp93nAEbczbNIzBI'
  },
  [ANALYTIKA_V2_EVENTS.acqu_view_init_page]: {
    VERSION: 1,
    SIGNATURE: 'AQEDEyB8AAAAAAAAALepwbVm9ENjmtYAtI6bp93nAEbczbNIzBI'
  },
  [ANALYTIKA_V2_EVENTS.acqu_press_button]: {
    VERSION: 2,
    SIGNATURE: 'AQIEEiB8AAAAAAAAAK1kT0CWuqMG6SffGfRRHcMVAAAV9GuA2PE'
  }
};

export const ADD_CAPTAIN = 'add-captain';
