import { HttpEvent, HttpInterceptor, HttpRequest, HttpHandler, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { CacheService } from '../cache.service';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  constructor(private readonly cache: CacheService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(this.cache.isRequestCachable(request)) {
      const cacheResponse = this.cache.get(request);
      if (cacheResponse) {
        return of(cacheResponse);
      } else {
        return next.handle(request).pipe(
          tap((event) => {
            if (event instanceof HttpResponse) {
              this.cache.set(request, event);
            }
          })
        );
      }
    } else {
      return next.handle(request);
    }
  }
}
